import React, {useEffect} from 'react';
import useState from "react-usestateref";
import Header from './Header';
import Sidebar from './Sidebar';
import ReactPaginate from 'react-paginate';
import apiService from "../core/sevice/detail";
import { getMethod,postMethod } from "../core/sevice/common.api";
import moment from "moment";
import {socket} from "../core/context/socket";
import toast, { Toaster }  from "react-hot-toast";
import * as XLSX from 'xlsx';

function TradeHistory() {

    const [filterStatus,setFilterStatus] =useState(false);
    const [isLoading,setIsLoading,isLoadingref] = useState(false);
    const [openOrders,setopenOrders,openOrdersref] = useState([]);
    const [CurrentPage, setCurrentPage,CurrentPageref] = useState(1);
    const [TotalPages, setTotalPages,TotalPagesref] = useState(0);
    const [search,setsearch,searchref] = useState("");

    const [search_trade,setsearch_trade,search_traderef] = useState("");
    const [tradeOrders,settradeOrders,tradeOrdersref] = useState([]);
    const [alltradeHistory,setalltradeHistory,alltradeHistoryref] = useState([]);
    const [TotalPages_trade, setTotalPages_trade,TotalPages_traderef] = useState(0);

    const [startDate, setstartDate, startDateref] = useState("");

    const [endDate, setendDate, endDateref] = useState("");

    const [type, setType, Typeref] = useState("open");

    useEffect(() => {
        // socket.connect();
        getTradeHistory(1);

        // socket.on("GetTradeOrders_user", async (response) => {
        //     //console.log("GetTradeOrders_user",response);
        //     if(response != null)
        //     {
        //      if(response.data.length > 0)
        //      {
        //         settradeOrders(response.data);
        //      }
        //     }
        //  });
        
      }, [0])

      const setfilterDate = async (val, type) => {
        if(type== "start")
        {
           setstartDate(val);
        }
        else
        {
           setendDate(val);
        }
        console.log("date value",val);
        if(val != "")
        {
          getTradeHistory_filter(1);
        }
        else
        {
          getTradeHistory(1);
        }
     };

      const tradeorder_socket = async() => {

        socket.on("GetTradeOrders_user", async (response) => {
            //console.log("GetTradeOrders_user",response);
            if(response != null)
            {
             if(response.data.length > 0)
             {
                settradeOrders(response.data);
             }
            }
         });
      }

      const getTradeHistory_socket = async(currentPage) => {
        let count = currentPage == undefined ? 1 : currentPage;
        try {
            var payload = {
                perPage: 10,
                page: count,
                search: search_traderef.current,
                startdate: startDateref.current,
                enddate: endDateref.current
            }
            console.log("payload",payload);
            socket.emit("GetTradeOrders",payload);
            
          } catch (error) {
          }
      }
      const getTradeHistory = async (currentPage) => {
        let count = currentPage == undefined ? 1 : currentPage;
        try {
          var payload = {
            perPage: 10,
            page: count,
            search: search_traderef.current,
            startdate: startDateref.current,
            enddate: endDateref.current
          }
          var data = {
            apiUrl: apiService.getTradeHistory,
            payload: payload
          };
          setIsLoading(true);
          var resp = await postMethod(data);
          console.log("trade history resp",resp);
          var order_resp = resp.data;
          setIsLoading(false);
          if (order_resp.status == true) {
            settradeOrders(order_resp.result);
            setTotalPages_trade(order_resp.pages);
          }

          setTimeout(async () => {
            getTradeHistory_socket(currentPage);
          },1000);
        } catch (error) {
          setIsLoading(false);
        }
      }
    
      const handlePageClick_trade = async (data) => {
        console.log(data.selected);
        let currentPage = data.selected + 1;
        getTradeHistory(currentPage);
      };

      const getTradeHistory_filter = async (currentPage) => {
        socket.off("GetTradeOrders_user");
        let count = currentPage == undefined ? 1 : currentPage;
        try {
          var payload = {
            perPage: 10,
            page: count,
            search: search_traderef.current,
            startdate: startDateref.current,
            enddate: endDateref.current
          }
          var data = {
            apiUrl: apiService.getTradeHistory,
            payload: payload
          };
          setIsLoading(true);
          var resp = await postMethod(data);
          console.log("trade history resp",resp);
          var order_resp = resp.data;
          setIsLoading(false);
          if (order_resp.status == true) {
            settradeOrders(order_resp.result);
            setTotalPages_trade(order_resp.pages);
          }
        } catch (error) {
          setIsLoading(false);
        }
      }

      const searchTradeOrders = async () => {
        if(search_traderef.current != "")
        {
            getTradeHistory_filter(1);
        }
        else
        {
            getTradeHistory(1);
            tradeorder_socket();
        }
        
      };

      const changeFilter = async(value) =>
      {
         if(value == "open")
         {
            setFilterStatus(false);
            // getOpenOrders(1);
         }
         else
         {
            setFilterStatus(true);
            getTradeHistory(1);
         }
         setType(value);
      };

      const downloadData = async () => {
        try {
            var data = {
                apiUrl: apiService.getalltradehistory,
              };
              var resp = await getMethod(data);
              console.log(resp,"resprespresprespresp");
              if (resp.status == true){
                setalltradeHistory(resp.data)
                const fieldsToExport = ['pair', 'buy_exchange', 'sell_exchange','profit_amount','strategy','updatedAt'];
                  console.log("Admin Details:", alltradeHistoryref.current);
                  const filteredData = alltradeHistoryref.current.map(item => {
                    const filteredItem = {};
                    fieldsToExport.forEach(field => {
                      filteredItem[field] = item[field];    
                    });
                    console.log("Filtered Item:", filteredItem);
                      return filteredItem;
                  })
                  console.log("Filtered Data:", filteredData);
                  const worksheet = XLSX.utils.json_to_sheet(filteredData);
                  const workbook = XLSX.utils.book_new();
                  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
                  XLSX.writeFile(workbook, 'TradeHistory.xlsx');
            
                  toast.success("File Downloaded")
              }
        } catch (error) {
          
        }
      }




  return (
    <div>
                       <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          duration: 4000,
        }}
      />
        <Header/>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-lg-2 col-sm-3'>
                <Sidebar/> 
                </div>
                <div className='col-lg-10 col-sm-9'>
                    <div className='container dashboard-container'>
                      <div className='profit_main_change'>
                      {/* <div className='profit-head'>
                      <span className='dashboard-portfolio'>Trade History</span>
                      <div className='profit_top_rght'>
                        <svg xmlns="http://www.w3.org/2000/svg" className='dash-bottom-img' viewBox="0 0 32 32" id="download" onClick={downloadData}><g fill="#8a8b8c" class="color000000 svgShape"><path d="M25,19a1,1,0,0,0-1,1v5H8V20a1,1,0,0,0-2,0v5.14A1.93,1.93,0,0,0,8,27H24a1.93,1.93,0,0,0,2-1.86V20A1,1,0,0,0,25,19Z" fill="#8a8b8c" class="color000000 svgShape"></path><path d="M15.27,20.68l0,0a1.2,1.2,0,0,0,.26.18l0,0h0A1,1,0,0,0,16,21a1,1,0,0,0,.38-.08l.12-.07a1.13,1.13,0,0,0,.18-.12l0,0,0,0,5-5.38a1,1,0,1,0-1.46-1.37L17,17.45V6a1,1,0,0,0-2,0V17.45l-3.27-3.52a1,1,0,1,0-1.46,1.37Z" fill="#8a8b8c" class="color000000 svgShape"></path></g></svg>
                      </div>
                       
                      </div> */}

                     <div className='profit-head'>
                      <span className='dashboard-portfolio'>Trade History</span>
                      <div className='profit_top_rght'>
                      <div className='profit-filter'>
                            <div className='order-from'>
                                <span className='order-from-fil'>From  </span>
                                <input 
                                type='date'
                                placeholder='from'
                                className='order-from-inp'
                                onChange={(e)=>setfilterDate(e.target.value,"start")}
                                />
                             </div>
                             <div className='order-from'>
                                <span className='order-from-fil'>To  </span>
                                <input 
                                type='date'
                                placeholder='to'
                                className='order-from-inp'
                                onChange={(e)=>setfilterDate(e.target.value,"end")}
                                />
                            </div>
                        </div>
                      <div className='order-search'>
                            <input 
                            type='text'
                            placeholder='Search'
                            className='order-srch-input'
                            onChange={(e) => setsearch_trade(e.target.value)}
                            />
                            <svg onClick={searchTradeOrders} xmlns="http://www.w3.org/2000/svg" className='search-icon' viewBox="0 0 96 96" id="Search"><switch><g fill="#8a8b8c" class="color000000 svgShape"><path d="M90.829 85.172 68.128 62.471A35.846 35.846 0 0 0 76 40C76 20.118 59.883 4 40 4 20.118 4 4 20.118 4 40s16.118 36 36 36c8.5 0 16.312-2.946 22.471-7.873l22.701 22.701A3.988 3.988 0 0 0 88 92a4 4 0 0 0 2.829-6.828zM40 68c-15.464 0-28-12.536-28-28s12.536-28 28-28c15.465 0 28 12.536 28 28S55.465 68 40 68z" fill="#8a8b8c" class="color000000 svgShape"></path></g></switch></svg>
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" className='dash-bottom-img' viewBox="0 0 32 32" id="download" onClick={downloadData}><g fill="#8a8b8c" class="color000000 svgShape"><path d="M25,19a1,1,0,0,0-1,1v5H8V20a1,1,0,0,0-2,0v5.14A1.93,1.93,0,0,0,8,27H24a1.93,1.93,0,0,0,2-1.86V20A1,1,0,0,0,25,19Z" fill="#8a8b8c" class="color000000 svgShape"></path><path d="M15.27,20.68l0,0a1.2,1.2,0,0,0,.26.18l0,0h0A1,1,0,0,0,16,21a1,1,0,0,0,.38-.08l.12-.07a1.13,1.13,0,0,0,.18-.12l0,0,0,0,5-5.38a1,1,0,1,0-1.46-1.37L17,17.45V6a1,1,0,0,0-2,0V17.45l-3.27-3.52a1,1,0,1,0-1.46,1.37Z" fill="#8a8b8c" class="color000000 svgShape"></path></g></svg>
                      </div>
                       
                      </div>
                      <div class="table-responsive">
                                <table className='market-table mt-5'>
                                    <thead className='market-head'>
                                        <th>Pair</th>
                                        <th>Buy Exchange</th>
                                        <th>Sell Exchange</th>
                                        <th>Buy Amount</th>
                                        <th>Sell Amount</th>
                                        <th>Profit</th>
                                        <th>Strategy</th>
                                        <th>Date & Time</th>
                                    </thead>
                                    <tbody>
                                         {isLoadingref.current == true ? (
                                            <>
                                            <tr className='text-center'>
                                                <td colSpan={12}>
                                                <div className="loader-css">
                                                <img
                                                    src={require("../images/Dual Ball@1x-1.0s-200px-200px.gif")}
                                                    alt="loader"
                                                    className="loader-gif"
                                                />
                                                </div>
                                                </td>
                                            </tr>
                                            
                                            </>
                                            ):(
                                            <>
                                            {tradeOrdersref.current.length > 0 ? (
                                            tradeOrdersref.current.map((element, index) => {
                                                var strategy = (element.strategy == "intra" || element.strategy == "dca") ? "Intra": element.strategy;
                                            return (
                                            <>
                                            <tr>
                                                <td>
                                                    <div className='order-place-frst'>
                                                        <span className='order-place-inner'>{element.pair}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span className='dash-btm-date'>{element.buy_exchange}</span>
                                                </td>
                                                <td>
                                                    <span className='dash-btm-date'>{element.sell_exchange}</span>
                                                </td>
                                                <td>
                                                <div className='dash_log_last'
                                                    >
                                                    <span className='order_buy'>{parseFloat(element.buy_price * element.buy_quantity).toFixed(6)}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                <div className='dash_log_lastloss'>
                                                    <span className='order_sell'> {parseFloat(element.sell_price * element.sell_quantity).toFixed(6)}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                <div className='dash_log_last'
                                                    >
                                                    <span className='order_buy'>{parseFloat(element.profit_amount).toFixed(6)}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span className='dash-btm-date'>{element.strategy}</span>
                                                </td>
                                                <td>
                                                    <span className='dash-btm-date'>{moment(element.updatedAt).format("lll")}</span>
                                                </td>
                                                </tr>
                                                </>
                                                )
                                                })) : (
                                                <tr className='text-center no-data-common'>
                                                    <td colSpan={12} className='p-5'>
                                                    No Datas Found
                                                    </td>
                                                </tr>
                                                )}
                                                </>
                                            )}
                                            
                                            {/* <tr>
                                                <td>
                                                    <div className='order-place-frst'>
                                                        <span className='order-place-inner'>SPEC / USDT</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span className='dash-btm-price'>18.93</span>
                                                </td>
                                                <td>
                                                    <span className='dash-btm-price'>7.5966</span>
                                                </td>
                                                <td>
                                                    <span className='dash-btm-price'>7.6876</span>
                                                </td>
                                                <td>
                                                    <span className='dash-btm-date'>143.803638</span>
                                                </td>
                                                <td>
                                                    <span className='dash-btm-date'>145.372516</span>
                                                </td>
                                                <td>
                                                    <span className='dash-btm-date'>1.57</span>
                                                </td>
                                                <td>
                                                    <span className='dash-btm-date'>1.09</span>
                                                </td>
                                                <td>
                                                    <span className='dash-btm-date'>BYBIT</span>
                                                </td>
                                                <td>
                                                    <span className='dash-btm-date'>BYBIT</span>
                                                </td>
                                                <td>
                                                    <span className='dash-btm-date'>Intra</span>
                                                </td>
                                                <td>
                                                    <span className='dash-btm-date'>06/05/2024</span>
                                                </td>
                                            </tr>
                                           */}
                                        {/* {tradeOrdersref.current.length > 0 ? ( */}
                                        <tr className='text-center text-white pagi_Btm_borno'>
                                        <td colSpan="12">
                                            <div className="paginationcss">
                                                <ReactPaginate
                                                    previousLabel={"<"}
                                                    nextLabel={">"}
                                                    breakLabel={"**"}
                                                    pageCount={TotalPages_traderef.current}
                                                    marginPagesDisplayed={1}
                                                    pageRangeDisplayed={0}
                                                    onPageChange={handlePageClick_trade}
                                                    containerClassName={"pagination pagination-md justify-content-center"}
                                                    pageClassName={"page-item"}
                                                    pageLinkClassName={"page-link"}
                                                    previousClassName={"page-item"}
                                                    previousLinkClassName={"page-link"}
                                                    nextClassName={"page-item"}
                                                    nextLinkClassName={"page-link"}
                                                    breakClassName={"page-item"}
                                                    breakLinkClassName={"page-link"}
                                                    activeClassName={"active"}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                        {/* ) : ("")} */}
                                </tbody>
                            </table>
                        </div>
                      </div>
                    
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default TradeHistory;