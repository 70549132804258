import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import apiService from "../core/sevice/detail";
import { postMethod } from "../core/sevice/common.api";
import { toast } from "react-toastify";
import { setAuthorization } from "../core/sevice/axios";
// import Header from './Header';

function Login() {
  const navigate = useNavigate();

  const initialFormValue = {
    email: "",
    password: "",
  };

  const [emailValidate, setemailValidate, emailValidateref] = useState(false);
  const [passwordValidate, setpasswordValidate, passwordValidateref] =
    useState(false);
  const [validationnErr, setvalidationnErr] = useState("");
  const [formValue, setFormValue] = useState(initialFormValue);
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [passHide, setPasshide] = useState(false);
  const [inputType, setinputType] = useState("password");

  const { email, password } = formValue;

  useEffect(() => {
    var token = localStorage.getItem("user_token");
    if (token) {
      navigate("/dashboard");
    }
  }, []);

  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    validate(formData);
  };

  const validate = async (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Email is a required field !";
      setemailValidate(true);
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address !";
      setemailValidate(true);
    } else {
      setemailValidate(false);
    }

    if (values.password == "") {
      setpasswordValidate(true);
      errors.password = "password is a required field !";
    } else if (values.password.length < 5 || values.password.length > 25) {
      setpasswordValidate(true);
      errors.password =
        "Password is required and shouldnot below 5 above 25 letters !";
    } else if (!values.password.match(/[a-z]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least lower character !";
    } else if (!values.password.match(/[A-Z]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least upper character !";
    } else if (!values.password.match(/[0-9]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at One digit character !";
    } else if (!values.password.match(/[!@#$%^&*]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least one special character !";
    } else {
      setpasswordValidate(false);
    }

    setvalidationnErr(errors);
    return errors;
  };

  const passwordHide = (data) => {
    if (data == "hide") {
      setPasshide(true);
      setinputType("text");
    } else {
      setPasshide(false);
      setinputType("password");
    }
  };

  const formSubmit = async () => {
    validate(formValue);
    if (
      emailValidateref.current === false &&
      passwordValidateref.current === false
    ) {
      console.log(formValue);
      var data = {
        apiUrl: apiService.signin,
        payload: formValue,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      setbuttonLoader(false);
      if (resp?.tfa === 1) {
        navigate("/verify-tfa", {
          state: {
            socketToken: resp?.socketToken,
          },
        });
      } else {
        if (resp.status == true) {
          setFormValue(initialFormValue);
          toast.success(resp.Message);
          console.log(resp, "[--=-=resp");
          await setAuthorization(resp.token);
          localStorage.setItem("user_token", resp.token);
          localStorage.setItem("tfa_status", resp.tfa);
          localStorage.setItem("socket_token", resp.socketToken);
          localStorage.setItem("jwNkiKmttscotlox", resp.jwNkiKmttscotlox);
          navigate("/dashboard");
        } else {
          setbuttonLoader(false);
          toast.error(resp.Message);
        }
      }
    }
  };

  return (
    <div className="login-back">
      <div className="landing-header1 p-4">
        <Link to="/dashboard">
          <img
            src={require("../images/daulah_holding_logo.png")}
            alt="logo"
            className="logo-img"
          />
        </Link>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 d-flex justify-content-center">
            <div className="login-card">
              <div className="login-inner-first">
                <div className="login-inner-top">
                  <span className="login-head"> Sign In</span>
                  <div className="login-inner-main">
                    <div className="login-inneronr-main">
                      <div className="login-main">
                        <div className="login-main-one mt-2">
                          <div className="login-email-flex">
                            <span className="login-email">E-mail</span>
                            <input
                              type="text"
                              placeholder="Enter your mail address"
                              className="login-input mt-1"
                              name="email"
                              value={email}
                              onChange={handleChange}
                            />
                          </div>
                          {emailValidate == true ? (
                            <p className="errorcss"> {validationnErr.email} </p>
                          ) : (
                            ""
                          )}
                          <div className="login-email-flex">
                            <span className="login-email">Password</span>
                            <div className="flex_input_posion">
                              <input
                                placeholder="Enter your password"
                                className="login-input mt-1"
                                type={inputType}
                                name="password"
                                value={password}
                                onChange={handleChange}
                                maxLength={40}
                              />
                              {passHide == false ? (
                                <i
                                  class="fa-regular fa-eye-slash login-pass-eye"
                                  onClick={() => passwordHide("hide")}
                                ></i>
                              ) : (
                                // <svg xmlns="http://www.w3.org/2000/svg" className="login-pass-eye" viewBox="0 0 24 24" onClick={() => passwordHide("hide")} id="Eye"><g fill="none" fill-rule="evenodd" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="colorStroke000000 svgStroke"><path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24M1 1l22 22" fill="#7d7e7f" class="color000000 svgShape"></path></g></svg>
                                <i
                                  class="fa-regular fa-eye login-pass-eye"
                                  onClick={() => passwordHide("show")}
                                ></i>
                              )}
                            </div>
                          </div>
                          {passwordValidate == true ? (
                            <p className="errorcss">
                              {validationnErr.password}{" "}
                            </p>
                          ) : (
                            ""
                          )}
                          <Link to="/forget">
                            <span className="login-forgot">
                              {" "}
                              Forget password ?
                            </span>
                          </Link>
                        </div>

                        {/* <div class="or-line-container my-3">
                          <div class="or-line"></div>
                          <div class="or-text">or</div>
                          <div class="or-line"></div>
                        </div> */}
                      </div>

                      {/* <div className="login-google">
                        <img
                          src={require("../images/Google.png")}
                          alt="google image"
                        />
                        <span className="continue-google">
                          {" "}
                          Continue with Google
                        </span>
                      </div> */}
                    </div>

                    <div>
                      {buttonLoader == false ? (
                        <button
                          className="login-submit-btn mt-4"
                          onClick={formSubmit}
                        >
                          <span className="login-submit">Sign In</span>
                        </button>
                      ) : (
                        <button className="login-submit-btn mt-4">
                          <span className="login-submit">Loading...</span>
                        </button>
                      )}
                    </div>
                  </div>
                  {/* <div className="login-sign-center">
                    <span className="login-footer">
                      Doesn’t have an account ?
                      <Link to="/signup">
                       <span className="login-create"> Sign Up.</span>
                      </Link>
                    </span>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
