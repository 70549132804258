import React,{ useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import Sidebar from "./Sidebar";
import apiService from "../core/sevice/detail";
import { postMethod } from "../core/sevice/common.api";
import { toast } from "react-toastify";

function ConnectExchange() {


  const initialFormValue = {
    exchange: ""
  };

  const [apikeyValidate, setapikeyValidate, apikeyValidateref] = useState(false);
  const [secretkeyValidate, setsecretkeyValidate, secretkeyValidateref] = useState(false);
  const [validationnErr, setvalidationnErr] = useState("");
  const [formValue, setFormValue] = useState(initialFormValue);
  const [buttonLoader, setbuttonLoader] = useState(false);

  const [formValues, setFormValues, formValuesref] = useState([]);

  const { exchange  } = formValue;

  useEffect(() => {
    // setInputList([{ apikey: "", secretkey: "" }]);
    // console.log("inputlist",inputListref.current)
  }, []);

  let addFormFields = () => {
    setFormValues([...formValuesref.current, { apikey: "", secretkey: "", passphrase: "" }])
  }

  let removeFormFields = (i) => {
    let newFormValues = [...formValuesref.current];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues)
  }

  let handleChange = (i, e) => {
    let newFormValues = [...formValuesref.current];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
    console.log("formValues",formValuesref.current);
    validate(i,formValuesref.current);
  }
 

  const setExchange = async (e) => {
    // e.preventDefault();
    console.log("value",e.target.getAttribute("data-value"));
    var exchange_value = e.target.getAttribute("data-value");
    formValue.exchange = e.target.getAttribute("data-value");
    if (exchange_value != null) {
      var data = {
        apiUrl: apiService.getkeys,
        payload: {exchange:exchange_value},
      };
      var resp = await postMethod(data);
      console.log("key response==",resp);
      if (resp.status == true) {
        setFormValues(resp.data);
        console.log("formValue==",formValuesref.current);
      }
      else
      {
        console.log("Call empty value")
        setFormValues([{ apikey: "", secretkey : "", passphrase: ""}]);
        console.log("Call formvalues",formValuesref.current);
        formValue.exchange = e.target.getAttribute("data-value");
      }
     }
  };

  const get_exchange = async (exchange) => {
    if (exchange != null) {
      var data = {
        apiUrl: apiService.getkeys,
        payload: {exchange:exchange},
      };
      var resp = await postMethod(data);
      console.log("key response==",resp);
      formValue.exchange = exchange;
      if (resp.status == true) {
        setFormValues(resp.data);
        console.log("formValue==",formValuesref.current);
      }
      else
      {
        setFormValues([{ apikey: "", secretkey : ""}]);
        formValue.exchange = exchange;
      }
     }
  };

  const validate = async (i,value) => {
    var values = value[i];
    const errors = {};
    // if (!values.exchange) {
    //   errors.exchange = "Exchange is a required field !";
    //   setexchangeValidate(true);
    // } else {
    //   setexchangeValidate(false);
    // }
    console.log("call validation")
    if (values.apikey == "" || values.apikey == null || values.apikey == undefined) {
      setapikeyValidate(true);
      errors.apikey = "Api key is a required field !";
      errors.index = i;
    }
    else if(values.apikey == "********")
    {
      console.log("call here")
      setapikeyValidate(true);
      errors.apikey = "Api key is a required field !";
      errors.index = i;
    }
    else {
      setapikeyValidate(false);
    }

    if (values.secretkey == "" || values.secretkey == null || values.secretkey == undefined) {
      setsecretkeyValidate(true);
      errors.secretkey = "Secret key is a required field !";
      errors.index = i;
    } 
    else if (values.secretkey == "********") {
      setsecretkeyValidate(true);
      errors.secretkey = "Secret key is a required field !";
      errors.index = i;
    } 
    else {
      setsecretkeyValidate(false);
    }

    setvalidationnErr(errors);
    return errors;
  };

  const formSubmit = async () => {
    if(formValuesref.current.length > 0)
    {
      for(var i=0; i<formValuesref.current.length; i++)
      {
        validate(i,formValuesref.current);
      }
    }
    if (
      apikeyValidateref.current === false &&
      secretkeyValidateref.current === false
    ) {
      console.log(formValuesref.current);
      var payload = {
        exchange: formValue.exchange,
        formvalues: formValuesref.current
      }
      var data = {
        apiUrl: apiService.apikeycreate,
        payload: payload,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      setbuttonLoader(false);
      if (resp.status == true) {
        get_exchange(formValue.exchange);
        toast.success(resp.message);
        console.log(resp, "[--=-=resp");
      } else {
        setbuttonLoader(false);
        toast.error(resp.message);
      }
    }
  };


  return (
    <div>
      <Header />
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2 col-sm-3">
            <Sidebar />
          </div>
          <div className="col-lg-10 col-sm-9">
            <div className="container dashboard-container">
              <span className="dashboard-portfolio">Connect Exchange</span>
              <div className="connect-row mt-3">
                <div className="row">
                  <div className="col-lg-3 mt-3">
                    <div className="connectex-card">
                      <div className="connectex-innercard">
                        <img
                          src={require("../images/connect exchange/Binance.png")}
                          className="connect-innerlogo"
                        />
                        <span className="connectex-name"> Binance </span>
                      </div>
                      <div className="conect-hover-img">
                        <img
                          src={require("../images/connect exchange/Hover-img.png")}
                          alt="Hover image"
                          className="connect-hover-size"
                          data-toggle="modal"
                          data-target="#exampleModalCenter"
                          data-value="BINANCE"
                          onClick={setExchange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 mt-3">
                    <div className="connectex-card">
                      <div className="connectex-innercard">
                        <img
                          src={require("../images/connect exchange/Bidget.png")}
                          className="connect-innerlogo"
                        />
                        <span className="connectex-name"> Bitget</span>
                      </div>
                      <div className="conect-hover-img">
                        <img
                          src={require("../images/connect exchange/Hover-img.png")}
                          alt="Hover image"
                          className="connect-hover-size"
                          data-toggle="modal"
                          data-target="#exampleModalCenter"
                          data-value="BITGET"
                          onClick={setExchange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 mt-3">
                    <div className="connectex-card">
                      <div className="connectex-innercard">
                        <img
                          src={require("../images/connect exchange/Bitmart.png")}
                          className="connect-innerlogo"
                        />
                        <span className="connectex-name"> Bitmart</span>
                      </div>
                      <div className="conect-hover-img">
                        <img
                          src={require("../images/connect exchange/Hover-img.png")}
                          alt="Hover image"
                          className="connect-hover-size"
                          data-toggle="modal"
                          data-target="#exampleModalCenter"
                          data-value="BITMART"
                          onClick={setExchange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 mt-3">
                    <div className="connectex-card">
                      <div className="connectex-innercard">
                        <img
                          src={require("../images/connect exchange/Bigone.png")}
                          className="connect-innerlogo"
                        />
                        <span className="connectex-name"> Bigone</span>
                      </div>
                      <div className="conect-hover-img">
                        <img
                          src={require("../images/connect exchange/Hover-img.png")}
                          alt="Hover image"
                          className="connect-hover-size"
                          data-toggle="modal"
                          data-target="#exampleModalCenter"
                          data-value="BIGONE"
                          onClick={setExchange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-3 mt-3">
                    <div className="connectex-card">
                      <div className="connectex-innercard">
                        <img
                          src={require("../images/connect exchange/Bingx.png")}
                          className="connect-innerlogo"
                        />
                        <span className="connectex-name"> Bingx</span>
                      </div>
                      <div className="conect-hover-img">
                        <img
                          src={require("../images/connect exchange/Hover-img.png")}
                          alt="Hover image"
                          className="connect-hover-size"
                          data-toggle="modal"
                          data-target="#exampleModalCenter"
                          data-value="BINGX"
                          onClick={setExchange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 mt-3">
                    <div className="connectex-card">
                      <div className="connectex-innercard">
                        <img
                          src={require("../images/connect exchange/Bybit.png")}
                          className="connect-innerlogo"
                        />
                        <span className="connectex-name"> Bybit</span>
                      </div>
                      <div className="conect-hover-img">
                        <img
                          src={require("../images/connect exchange/Hover-img.png")}
                          alt="Hover image"
                          className="connect-hover-size"
                          data-toggle="modal"
                          data-target="#exampleModalCenter"
                          data-value="BYBIT"
                          onClick={setExchange}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-lg-3 mt-3">
                    <div className="connectex-card">
                      <div className="connectex-innercard">
                        <img
                          src={require("../images/connect exchange/Bitrue.png")}
                          className="connect-innerlogo"
                        />
                        <span className="connectex-name"> Bitrue</span>
                      </div>
                      <div className="conect-hover-img">
                        <img
                          src={require("../images/connect exchange/Hover-img.png")}
                          alt="Hover image"
                          className="connect-hover-size"
                          data-toggle="modal"
                          data-target="#exampleModalCenter"
                          data-value="BITRUE"
                          onClick={setExchange}
                        />
                      </div>
                    </div>
                  </div> */}
                  <div className="col-lg-3 mt-3">
                    <div className="connectex-card">
                      <div className="connectex-innercard">
                        <img
                          src={require("../images/connect exchange/Digitinex.png")}
                          className="connect-innerlogo"
                        />
                        <span className="connectex-name"> Digifinex</span>
                      </div>
                      <div className="conect-hover-img">
                        <img
                          src={require("../images/connect exchange/Hover-img.png")}
                          alt="Hover image"
                          className="connect-hover-size"
                          data-toggle="modal"
                          data-target="#exampleModalCenter"
                          data-value="DIGIFINEX"
                          onClick={setExchange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 mt-3">
                    <div className="connectex-card">
                      <div className="connectex-innercard">
                        <img
                          src={require("../images/connect exchange/Gateio.png")}
                          className="connect-innerlogo"
                        />
                        <span className="connectex-name"> Gate.io</span>
                      </div>
                      <div className="conect-hover-img">
                        <img
                          src={require("../images/connect exchange/Hover-img.png")}
                          alt="Hover image"
                          className="connect-hover-size"
                          data-toggle="modal"
                          data-target="#exampleModalCenter"
                          data-value="GATE.IO"
                          onClick={setExchange}
                        />
                      </div>
                    </div>
                  </div>

                </div>
                <div className="row">
                  
                  <div className="col-lg-3 mt-3">
                    <div className="connectex-card">
                      <div className="connectex-innercard">
                        <img
                          src={require("../images/connect exchange/Haubi.png")}
                          className="connect-innerlogo"
                        />
                        <span className="connectex-name"> Huobi</span>
                      </div>
                      <div className="conect-hover-img">
                        <img
                          src={require("../images/connect exchange/Hover-img.png")}
                          alt="Hover image"
                          className="connect-hover-size"
                          data-toggle="modal"
                          data-target="#exampleModalCenter"
                          data-value="HUOBI"
                          onClick={setExchange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 mt-3">
                    <div className="connectex-card">
                      <div className="connectex-innercard">
                        <img
                          src={require("../images/connect exchange/Kucoin.png")}
                          className="connect-innerlogo"
                        />
                        <span className="connectex-name"> Kucoin</span>
                      </div>
                      <div className="conect-hover-img">
                        <img
                          src={require("../images/connect exchange/Hover-img.png")}
                          alt="Hover image"
                          className="connect-hover-size"
                          data-toggle="modal"
                          data-target="#exampleModalCenter"
                          data-value="KUCOIN"
                          onClick={setExchange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 mt-3">
                    <div className="connectex-card">
                      <div className="connectex-innercard">
                        <img
                          src={require("../images/connect exchange/Mexc.png")}
                          className="connect-innerlogo"
                        />
                        <span className="connectex-name"> Mexc</span>
                      </div>
                      <div className="conect-hover-img">
                        <img
                          src={require("../images/connect exchange/Hover-img.png")}
                          alt="Hover image"
                          className="connect-hover-size"
                          data-toggle="modal"
                          data-target="#exampleModalCenter"
                          data-value="MEXC"
                          onClick={setExchange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 mt-3">
                    <div className="connectex-card">
                      <div className="connectex-innercard">
                        <img
                          src={require("../images/connect exchange/OKX.png")}
                          className="connect-innerlogo"
                        />
                        <span className="connectex-name"> OKX</span>
                      </div>
                      <div className="conect-hover-img">
                        <img
                          src={require("../images/connect exchange/Hover-img.png")}
                          alt="Hover image"
                          className="connect-hover-size"
                          data-toggle="modal"
                          data-target="#exampleModalCenter"
                          data-value="OKX"
                          onClick={setExchange}
                        />
                      </div>
                    </div>
                  </div>

                </div>
                <div className="row">
                  {/* <div className="col-lg-3 mt-3">
                    <div className="connectex-card">
                      <div className="connectex-innercard">
                        <img
                          src={require("../images/connect exchange/OKX.png")}
                          className="connect-innerlogo"
                        />
                        <span className="connectex-name"> OKX</span>
                      </div>
                      <div className="conect-hover-img">
                        <img
                          src={require("../images/connect exchange/Hover-img.png")}
                          alt="Hover image"
                          className="connect-hover-size"
                          data-toggle="modal"
                          data-target="#exampleModalCenter"
                          data-value="OKX"
                          onClick={setExchange}
                        />
                      </div>
                    </div>
                  </div> */}

                  {/* <div className="col-lg-3 mt-3">
                    <div className="connectex-card">
                      <div className="connectex-innercard">
                        <img
                          src={require("../images/connect exchange/Poloniex.png")}
                          className="connect-innerlogo"
                        />
                        <span className="connectex-name"> Poloniex</span>
                      </div>
                      <div className="conect-hover-img">
                        <img
                          src={require("../images/connect exchange/Hover-img.png")}
                          alt="Hover image"
                          className="connect-hover-size"
                          data-toggle="modal"
                          data-target="#exampleModalCenter"
                          data-value="POLONIEX"
                          onClick={setExchange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 mt-3">
                    <div className="connectex-card">
                      <div className="connectex-innercard">
                        <img
                          src={require("../images/connect exchange/Hibtc.png")}
                          className="connect-innerlogo"
                        />
                        <span className="connectex-name"> Hibtc</span>
                      </div>
                      <div className="conect-hover-img">
                        <img
                          src={require("../images/connect exchange/Hover-img.png")}
                          alt="Hover image"
                          className="connect-hover-size"
                          data-toggle="modal"
                          data-target="#exampleModalCenter"
                          data-value="HITBTC"
                          onClick={setExchange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 mt-3">
                    <div className="connectex-card">
                      <div className="connectex-innercard">
                        <img
                          src={require("../images/connect exchange/Coinex.png")}
                          className="connect-innerlogo"
                        />
                        <span className="connectex-name"> Coinex</span>
                      </div>
                      <div className="conect-hover-img">
                        <img
                          src={require("../images/connect exchange/Hover-img.png")}
                          alt="Hover image"
                          className="connect-hover-size"
                          data-toggle="modal"
                          data-target="#exampleModalCenter"
                          data-value="COINEX"
                          onClick={setExchange}
                        />
                      </div>
                    </div>
                  </div> */}

                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="exampleModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
        
          <div class="modal-content">
            <div class="modal-header">
            
              <p class="modal-title" id="exampleModalLongTitle">
                
                <div className="modal-head-add" onClick={() => addFormFields()}>
                  <img
                    src={require("../images/connect exchange/Hover-img.png")}
                    alt="add icon"
                    className="modal-add-btn"
                  />
                  <span className="modal-add-txt" >Add</span>
                </div>
                
              </p>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
           
            <div class="modal-body">

            { formValuesref.current.length > 0 ? (
            formValuesref.current.map((element, index) => (
              <div className="modal-body-contnt">

              <div className="modal-body-first">
                  <label className="d-block dash-btm-price">API key</label>
                  <input
                    type="text"
                    placeholder="Wjhbd556Jsfg65dj..."
                    className="modal-input"
                    name="apikey"
                    value={element.apikey || ""}
                    onChange={e => handleChange(index, e)}
                />
                </div>
                {apikeyValidate == true ? (
                  validationnErr.index == index ? (
                  <p className="errorcss"> {validationnErr.apikey} </p>
                    ) : ("")): ("")}

                 <div className="modal-body-first">
                  <label className="d-block dash-btm-price">Secret Key</label>
                  <input
                    type="text"
                    placeholder="Jsfg65djWjhbd556..."
                    className="modal-input"
                    name="secretkey"
                    value={element.secretkey || ""}
                    onChange={e => handleChange(index, e)}
                  />
                </div>
                {secretkeyValidate == true ? (
                  validationnErr.index == index ? (
                  <p className="errorcss"> {validationnErr.secretkey} </p>
                    ) : ("")): ("")}

                <div className="modal-body-first">
                  <label className="d-block dash-btm-price">Passphrase</label>
                  <input
                    type="text"
                    placeholder="Jsfg65djWjhbd556..."
                    className="modal-input"
                    name="passphrase"
                    value={element.passphrase || ""}
                    onChange={e => handleChange(index, e)}
                  />
                </div>
                <div key={index} className="cnctadd-remove">
                  {
                    index ? 
                    <p class="modal-title" id="exampleModalLongTitle">
                    
                    <div className="modal-head-add" onClick={() => removeFormFields(index)}>
                      <img
                        src={require("../images/connect exchange/Hover-img-remove.png")}
                        alt="remove icon"
                        className="modal-add-btn"
                      />
                      <span className="modal-remove-txt" > Remove</span>
                    </div>
                    
                  </p>
                    : null
                  }
                </div>
            </div>
          )) ) : ("")}
            {/* { inputListref.current && inputListref.current.length > 0 &&
            inputListref.current.map((x, i) => {
              console.log("input map==",x);
              return ( */}
              {/* <div> */}
              {/* <div className="modal-body-contnt">
                <div className="modal-body-first">
                  <label className="d-block dash-btm-price">API key</label>
                  <input
                    type="text"
                    placeholder="Wjhbd556Jsfg65dj..."
                    className="modal-input"
                    name="apikey"
                    value={apikey}
                    onChange={handleChange}
                />
                </div>
                {apikeyValidate == true ? (
                  <p className="errorcss"> {validationnErr.apikey} </p>
                    ) : (
                    ""
                  )} */}
                {/* <div className="modal-body-first">
                  <label className="d-block dash-btm-price">Secret Key</label>
                  <input
                    type="text"
                    placeholder="Jsfg65djWjhbd556..."
                    className="modal-input"
                    name="secretkey"
                    value={secretkey}
                    onChange={handleChange}
                  />
                </div>
                {secretkeyValidate == true ? (
                  <p className="errorcss"> {validationnErr.secretkey} </p>
                    ) : (
                    ""
                  )} */}
              </div>

              {/* <div className="modal-head-add">
                <img
                  src={require("../images/connect exchange/Hover-img.png")}
                  alt="add icon"
                  className="modal-add-btn"
                />
                <span className="modal-add-txt" onClick={handleRemoveClick(i)}>Remove</span>
                </div>

              </div> */}

                
           
                {/* );
              })} */}
            {/* </div> */}
            
            <div class="modal-footer">
            {buttonLoader == false ? (
              <button
                type="button"
                class="btn btn-secondary modal-submit"
                onClick={formSubmit}
              >
                Submit
              </button>
            ) : (
              <button
              type="button"
              class="btn btn-secondary modal-submit"
            >
              Loading...
            </button>
            )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConnectExchange;
