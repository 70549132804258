import React, { useEffect } from 'react';
import useState from "react-usestateref";
import Sidebar from './Sidebar';
import Header from './Header';
import ReactPaginate from "react-paginate";
import apiService from "../core/sevice/detail";
import { postMethod } from "../core/sevice/common.api";
import moment from "moment";
import {socket} from "../core/context/socket";


function Orders() {

    const [filterStatus,setFilterStatus] =useState(false);
    const [isLoading,setIsLoading,isLoadingref] = useState(false);
    const [openOrders,setopenOrders,openOrdersref] = useState([]);
    const [CurrentPage, setCurrentPage,CurrentPageref] = useState(1);
    const [TotalPages, setTotalPages,TotalPagesref] = useState(0);
    const [search,setsearch,searchref] = useState("");

    const [search_trade,setsearch_trade,search_traderef] = useState("");
    const [tradeOrders,settradeOrders,tradeOrdersref] = useState([]);
    const [TotalPages_trade, setTotalPages_trade,TotalPages_traderef] = useState(0);

    const [startDate, setstartDate, startDateref] = useState("");

    const [endDate, setendDate, endDateref] = useState("");

    const [type, setType, Typeref] = useState("open");

    useEffect(() => {
        socket.connect();
        getOpenOrders(1);
        
       
        // socket.on("GetOpenOrders_user", async (response) => {
        //    console.log("GetOpenOrders_user",response);
        //    if(response != null)
        //    {
        //     if(response.data.length > 0)
        //     {
        //         setopenOrders(response.data);
        //     }
        //    }
        // });


        // socket.on("GetTradeOrders_user", async (response) => {
        //     //console.log("GetTradeOrders_user",response);
        //     if(response != null)
        //     {
        //      if(response.data.length > 0)
        //      {
        //         settradeOrders(response.data);
        //      }
        //     }
        //  });
        
      }, [0])

      const openorder_socket = async() => {
        socket.on("GetOpenOrders_user", async (response) => {
            console.log("GetOpenOrders_user",response);
            if(response != null)
            {
             if(response.data.length > 0)
             {
                 setopenOrders(response.data);
             }
            }
         });
      }

      const tradeorder_socket = async() => {

        socket.on("GetTradeOrders_user", async (response) => {
            //console.log("GetTradeOrders_user",response);
            if(response != null)
            {
             if(response.data.length > 0)
             {
                settradeOrders(response.data);
             }
            }
         });
      }

    const getOpenOrders_socket = async(currentPage) => {
        let count = currentPage == undefined ? 1 : currentPage;
        try {
            var payload = {
              perPage: 10,
              page: count,
              search: searchref.current
            }
            // console.log("call socket emit");
            // console.log("payload",payload);
            socket.emit("GetOpenOrders",payload);
            
          } catch (error) {

          }
    }
    const getOpenOrders = async (currentPage) => {
        let count = currentPage == undefined ? 1 : currentPage;
        try {
          var payload = {
            perPage: 10,
            page: count,
            search: searchref.current
          }
          var data = {
            apiUrl: apiService.getOpenOrders,
            payload: payload
          };
          setIsLoading(true);
          var resp = await postMethod(data);
          console.log("open orders resp",resp);
          var order_resp = resp.data;
          setIsLoading(false);
          if (order_resp.status == true) {
            setopenOrders(order_resp.result);
            setTotalPages(order_resp.pages);
          }
        //    setTimeout(async () => {
        //      getOpenOrders_socket(currentPage);
        //    },1000);
        } catch (error) {
          setIsLoading(false);
        }
      }
    
      const handlePageClick = async (data) => {
        console.log(data.selected);
        let currentPage = data.selected + 1;
        getOpenOrders(currentPage);
      };

      const getOpenOrders_filter = async (currentPage) => {
        socket.off("GetOpenOrders_user");
        let count = currentPage == undefined ? 1 : currentPage;
        try {
          var payload = {
            perPage: 10,
            page: count,
            search: searchref.current
          }
          var data = {
            apiUrl: apiService.getOpenOrders,
            payload: payload
          };
          setIsLoading(true);
          var resp = await postMethod(data);
          console.log("open orders resp",resp);
          var order_resp = resp.data;
          setIsLoading(false);
          if (order_resp.status == true) {
            setopenOrders(order_resp.result);
            setTotalPages(order_resp.pages);
          }
        } catch (error) {
          setIsLoading(false);
        }
      }

      const searchOpenOrders = async () => {
        if(searchref.current != "")
         {
            getOpenOrders_filter(1);
         }
         else
         {
            getOpenOrders(1);
            openorder_socket();
         }
      };

      const setfilterDate = async (val, type) => {
         if(type== "start")
         {
            setstartDate(val);
         }
         else
         {
            setendDate(val);
         }
         if(val != "")
         {
            getTradeHistory_filter(1);
         }
         else
         {
            getTradeHistory(1);
            tradeorder_socket();
         }
         
      };

      const getTradeHistory_socket = async(currentPage) => {
        let count = currentPage == undefined ? 1 : currentPage;
        try {
            var payload = {
                perPage: 10,
                page: count,
                search: search_traderef.current,
                startdate: startDateref.current,
                enddate: endDateref.current
            }
            console.log("payload",payload);
            socket.emit("GetTradeOrders",payload);
            
          } catch (error) {
          }
      }
      const getTradeHistory = async (currentPage) => {
        let count = currentPage == undefined ? 1 : currentPage;
        try {
          var payload = {
            perPage: 10,
            page: count,
            search: search_traderef.current,
            startdate: startDateref.current,
            enddate: endDateref.current
          }
          var data = {
            apiUrl: apiService.getTradeHistory,
            payload: payload
          };
          setIsLoading(true);
          var resp = await postMethod(data);
          console.log("trade history resp",resp);
          var order_resp = resp.data;
          setIsLoading(false);
          if (order_resp.status == true) {
            settradeOrders(order_resp.result);
            setTotalPages_trade(order_resp.pages);
          }

        //   setTimeout(async () => {
        //     getTradeHistory_socket(currentPage);
        //   },1000);
        } catch (error) {
          setIsLoading(false);
        }
      }
    
      const handlePageClick_trade = async (data) => {
        console.log(data.selected);
        let currentPage = data.selected + 1;
        getTradeHistory(currentPage);
      };

      const getTradeHistory_filter = async (currentPage) => {
        socket.off("GetTradeOrders_user");
        let count = currentPage == undefined ? 1 : currentPage;
        try {
          var payload = {
            perPage: 10,
            page: count,
            search: search_traderef.current,
            startdate: startDateref.current,
            enddate: endDateref.current
          }
          var data = {
            apiUrl: apiService.getTradeHistory,
            payload: payload
          };
          setIsLoading(true);
          var resp = await postMethod(data);
          console.log("trade history resp",resp);
          var order_resp = resp.data;
          setIsLoading(false);
          if (order_resp.status == true) {
            settradeOrders(order_resp.result);
            setTotalPages_trade(order_resp.pages);
          }
        } catch (error) {
          setIsLoading(false);
        }
      }

      const searchTradeOrders = async () => {
        if(search_traderef.current != "")
        {
            getTradeHistory_filter(1);
        }
        else
        {
            getTradeHistory(1);
            tradeorder_socket();
        }
        
      };

      const changeFilter = async(value) =>
      {
         if(value == "open")
         {
            setFilterStatus(false);
            getOpenOrders(1);
         }
         else
         {
            setFilterStatus(true);
            getTradeHistory(1);
         }
         setType(value);
      };

    return (
        <div>
            <Header />
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-lg-2 col-sm-3'>
                        <Sidebar />
                    </div>
                    <div className='col-lg-10 col-sm-9'>
                        <div className='container-fluid dashboard-container'>
                            <span className='dashboard-portfolio'>Orders</span>
                            <div className='order-top-full'>
                                <div className='order-top-left'>
                                    <div className='order-left-one'>
                                       <div className='d-flex justify-content-between'>
                                        <div>
                                        <ul class="nav nav-tabs mt-2">
                                            <li class="active">
                                                <a data-toggle="tab" href="#home" className="active">
                                                    <button className='order-btn-place' onClick={()=>changeFilter("open")}>
                                                        <span className=''> Open Order</span>
                                                    </button>
                                                </a>
                                            </li>
                                            <li>
                                                <a data-toggle="tab" href="#menu2">
                                                    <button className='order-btn-place' onClick={()=>changeFilter("trade")}>
                                                        <span className=''> Trade History</span>
                                                    </button>
                                                </a>
                                            </li>
                                            {filterStatus == true ? (
                                                <>
                                                  <li>
                                                    <div className='order-from'>
                                                        <span className='order-from-fil'>From  </span>
                                                        <input 
                                                        type='date'
                                                        placeholder='from'
                                                        className='order-from-inp'
                                                        onChange={(e)=>setfilterDate(e.target.value,"start")}
                                                        />
                                                    </div>
                                                  </li>
                                                  <li>
                                                    <div className='order-from'>
                                                        <span className='order-from-fil'>To  </span>
                                                        <input 
                                                        type='date'
                                                        placeholder='to'
                                                        className='order-from-inp'
                                                        onChange={(e)=>setfilterDate(e.target.value,"end")}
                                                        />
                                                    </div>
                                                  </li>
                                                </>
                                            ):(
                                                ""
                                            )}
                                        </ul>
                                        </div>
                                        {Typeref.current == "open" ? (
                                            <div className='order-search'>
                                            <input 
                                            type='text'
                                            placeholder='Search'
                                            className='order-srch-input'
                                            onChange={(e) => setsearch(e.target.value)}
                                            />
                                            <svg onClick={searchOpenOrders} xmlns="http://www.w3.org/2000/svg" className='search-icon' viewBox="0 0 96 96" id="Search"><switch><g fill="#8a8b8c" class="color000000 svgShape"><path d="M90.829 85.172 68.128 62.471A35.846 35.846 0 0 0 76 40C76 20.118 59.883 4 40 4 20.118 4 4 20.118 4 40s16.118 36 36 36c8.5 0 16.312-2.946 22.471-7.873l22.701 22.701A3.988 3.988 0 0 0 88 92a4 4 0 0 0 2.829-6.828zM40 68c-15.464 0-28-12.536-28-28s12.536-28 28-28c15.465 0 28 12.536 28 28S55.465 68 40 68z" fill="#8a8b8c" class="color000000 svgShape"></path></g></switch></svg>
                                           </div>
                                        ) : (
                                        <div className='order-search'>
                                            <input 
                                            type='text'
                                            placeholder='Search'
                                            className='order-srch-input'
                                            onChange={(e) => setsearch_trade(e.target.value)}
                                            />
                                            <svg onClick={searchTradeOrders} xmlns="http://www.w3.org/2000/svg" className='search-icon' viewBox="0 0 96 96" id="Search"><switch><g fill="#8a8b8c" class="color000000 svgShape"><path d="M90.829 85.172 68.128 62.471A35.846 35.846 0 0 0 76 40C76 20.118 59.883 4 40 4 20.118 4 4 20.118 4 40s16.118 36 36 36c8.5 0 16.312-2.946 22.471-7.873l22.701 22.701A3.988 3.988 0 0 0 88 92a4 4 0 0 0 2.829-6.828zM40 68c-15.464 0-28-12.536-28-28s12.536-28 28-28c15.465 0 28 12.536 28 28S55.465 68 40 68z" fill="#8a8b8c" class="color000000 svgShape"></path></g></switch></svg>
                                        </div>
                                        )}
                                       </div>
                                        <div class="tab-content">
                                            <div id="home" class="tab-pane fade in active show">
                                                <div class="table-responsive">
                                                    <table className='market-table mt-4'>
                                                        <thead className='market-head'>
                                                            <th>Pair</th>
                                                            <th>Quantity</th>
                                                            <th>Price</th>
                                                            <th>Total</th>
                                                            <th>Buy Exchange</th>
                                                            <th>Strategy</th>
                                                            <th>Status</th>
                                                        </thead>
                                                        <tbody>
                                                        {isLoadingref.current == true ? (
                                                            <>
                                                            <tr className='text-center'>
                                                                <td colSpan={7}>
                                                                <div className="loader-css">
                                                                <img
                                                                    src={require("../images/Dual Ball@1x-1.0s-200px-200px.gif")}
                                                                    alt="loader"
                                                                    className="loader-gif"
                                                                />
                                                                </div>
                                                                </td>
                                                            </tr>
                                                            
                                                            </>
                                                            ):(
                                                         <>
                                                        {openOrdersref.current.length > 0 ? (
                                                          openOrdersref.current.map((element, index) => {
                                                            var strategy = (element.strategy == "intra" || element.strategy == "dca") ? "Intra": element.strategy;
                                                            console.log("strategy",strategy)
                                                            return (
                                                            <>
                                                            <tr>
                                                                <td>
                                                                    <div className='order-place-frst'>
                                                                        <span className='order-place-inner'>{element.primary+"/"+element.secondary}</span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'>{parseFloat(element.buy_quantity).toFixed(6)}</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'>{parseFloat(element.buy_price).toFixed(6)}</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'>{parseFloat(element.buy_quantity * element.buy_price).toFixed(6)}</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>{element.buy_exchange}</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>{strategy}</span>
                                                                </td>
                                                                <td>
                                                                    <span className='order-open'> Open</span>
                                                                </td>
                                                            </tr>
                                                            </>
                                                             );
                                                            })
                                                            ) : (
                                                                <tr className='text-white no-data-common'>
                                                                    <td colspan="7" className='p-5'>No Datas Found</td>
                                                                </tr>
                                                            )}
                                                            </>
                                                            )}
                                                            {/* <tr>
                                                                <td>
                                                                    <div className='order-place-frst'>
                                                                        <span className='order-place-inner'> ETH / USDT</span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'>0.03</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'> 2989.310000</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'> 79.216715</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'> DIGIFINEX</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>Intra</span>
                                                                </td>
                                                                <td>
                                                                    <span className='order-open'> Open</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <div className='order-place-frst'>
                                                                        <span className='order-place-inner'> SPEC / USDT</span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'> 19.88</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'> 7.156500</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'>142.271220</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'> BYBIT</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>Intra</span>
                                                                </td>
                                                                <td>
                                                                    <span className='order-open'> Open</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <div className='order-place-frst'>
                                                                        <span className='order-place-inner'> SPEC / USDT</span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'> 19.88</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'> 7.156500</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'>142.271220</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'> BYBIT</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>Intra</span>
                                                                </td>
                                                                <td>
                                                                    <span className='order-open'> Open</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <div className='order-place-frst'>
                                                                        <span className='order-place-inner'>SPEC / USDT</span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'>18.93</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'>7.596600</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'>143.803638</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>BYBIT</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>Intra</span>
                                                                </td>
                                                                <td>
                                                                    <span className='order-open'> Open</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <div className='order-place-frst'>
                                                                        <span className='order-place-inner'>SPEC / USDT</span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'>18.93</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'>7.596600</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'>143.803638</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>BYBIT</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>Intra</span>
                                                                </td>
                                                                <td>
                                                                    <span className='order-open'> Open</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <div className='order-place-frst'>
                                                                        <span className='order-place-inner'> ETH / USDT</span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'>0.03</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'> 2989.310000</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'> 79.216715</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'> DIGIFINEX</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>Intra</span>
                                                                </td>
                                                                <td>
                                                                    <span className='order-open'> Open</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <div className='order-place-frst'>
                                                                        <span className='order-place-inner'>SPEC / USDT</span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'>18.93</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'>7.596600</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'>143.803638</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>BYBIT</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>Intra</span>
                                                                </td>
                                                                <td>
                                                                    <span className='order-open'> Open</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <div className='order-place-frst'>
                                                                        <span className='order-place-inner'> ETH / USDT</span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'>0.03</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'> 2989.310000</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'> 79.216715</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'> DIGIFINEX</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>Intra</span>
                                                                </td>
                                                                <td>
                                                                    <span className='order-open'> Open</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <div className='order-place-frst'>
                                                                        <span className='order-place-inner'>SPEC / USDT</span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'>18.93</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'>7.596600</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'>143.803638</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>BYBIT</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>Intra</span>
                                                                </td>
                                                                <td>
                                                                    <span className='order-open'> Open</span>
                                                                </td>
                                                            </tr> */}

{openOrdersref.current.length > 0 ? (
    <tr className='text-center text-white'>
    <td colSpan="7">
        <div className="paginationcss">
            <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"**"}
                pageCount={TotalPagesref.current}
                marginPagesDisplayed={1}
                pageRangeDisplayed={0}
                onPageChange={handlePageClick}
                containerClassName={"pagination pagination-md justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
            />
        </div>
    </td>
</tr>
) : ("")}
                                                        
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div id="menu2" class="tab-pane fade">
                                                <div class="table-responsive">
                                                    <table className='market-table mt-4'>
                                                        <thead className='market-head'>
                                                            <th>Pair</th>
                                                            <th>Buy Exchange</th>
                                                            <th>Sell Exchange</th>
                                                            <th>Buy Amount</th>
                                                            <th>Sell Amount</th>
                                                            <th>Profit</th>
                                                            <th>Strategy</th>
                                                            <th>Date & Time</th>
                                                        </thead>
                                                        <tbody>
                                                        {tradeOrdersref.current.length > 0 ? (
                                                          tradeOrdersref.current.map((element, index) => {
                                                            var strategy = (element.strategy == "intra" || element.strategy == "dca") ? "Intra": element.strategy;
                                                            return (
                                                            <>
                                                            <tr>
                                                                <td>
                                                                    <div className='order-place-frst'>
                                                                        <span className='order-place-inner'>{element.primary+"/"+element.secondary}</span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'>{element.buy_exchange}</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'>{element.sell_exchange}</span>
                                                                </td>
                                                                <td>
                                                                    <span className='order_buy'> {parseFloat(element.buy_price * element.buy_quantity).toFixed(6)}</span>
                                                                </td>
                                                                <td>
                                                                    <span className='order_sell'> {parseFloat(element.sell_price * element.sell_quantity).toFixed(6)}</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>{parseFloat(element.profit_amount).toFixed(6)}</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>{strategy}</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>{moment(element.updatedAt).format("DD/MM/YYYY hh:mm:ss")}</span>
                                                                </td>
                                                            </tr>
                                                            </>
                                                             );
                                                            })
                                                            ) : (
                                                                <tr className='no-data-common'>
                                                                    <td colspan="8" className='p-5'>No Datas Found</td>
                                                                </tr>
                                                            )}
                                                            {/* <tr>
                                                                <td>
                                                                    <div className='order-place-frst'>
                                                                        <span className='order-place-inner'>IOST / USDT</span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'>BYBIT</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'>BYBIT</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'> 69.3823</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>69.964794</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>0.58</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>Intra</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>06/05/20224</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <div className='order-place-frst'>
                                                                        <span className='order-place-inner'>XRP / USDT</span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'> DIGIFINEX</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'> DIGIFINEX</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'>  59.815665</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>  69.050138</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>0.51</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>Intra</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>06/05/20224</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <div className='order-place-frst'>
                                                                        <span className='order-place-inner'>CELL / USDT</span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'>BYBIT</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'>BYBIT</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'> 100.02</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'> 101.02</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>1.00</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>Intra</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>06/05/20224</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <div className='order-place-frst'>
                                                                        <span className='order-place-inner'>XRP / USDT</span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'> DIGIFINEX</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'> DIGIFINEX</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'>  59.815665</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>  69.050138</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>0.51</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>Intra</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>06/05/20224</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <div className='order-place-frst'>
                                                                        <span className='order-place-inner'>IOST / USDT</span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'>BYBIT</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'>BYBIT</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'> 69.3823</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>69.964794</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>0.58</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>Intra</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>06/05/20224</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <div className='order-place-frst'>
                                                                        <span className='order-place-inner'>XRP / USDT</span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'> DIGIFINEX</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'> DIGIFINEX</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'>  59.815665</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>  69.050138</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>0.51</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>Intra</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>06/05/20224</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <div className='order-place-frst'>
                                                                        <span className='order-place-inner'>CELL / USDT</span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'>BYBIT</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'>BYBIT</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'> 100.02</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'> 101.02</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>1.00</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>Intra</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>06/05/20224</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <div className='order-place-frst'>
                                                                        <span className='order-place-inner'>IOST / USDT</span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'>BYBIT</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'>BYBIT</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'> 69.3823</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>69.964794</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>0.58</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>Intra</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>06/05/20224</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <div className='order-place-frst'>
                                                                        <span className='order-place-inner'>CELL / USDT</span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'>BYBIT</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'>BYBIT</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'> 100.02</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'> 101.02</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>1.00</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>Intra</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>06/05/20224</span>
                                                                </td>
                                                            </tr> */}

{tradeOrdersref.current.length > 0 ? (
     <tr className='text-center text-white'>
     <td colSpan="8">
         <div className="paginationcss">
             <ReactPaginate
                 previousLabel={"<"}
                 nextLabel={">"}
                 breakLabel={""}
                 pageCount={TotalPages_traderef.current}
                 marginPagesDisplayed={1}
                 pageRangeDisplayed={0}
                 onPageChange={handlePageClick_trade}
                 containerClassName={"pagination pagination-md justify-content-center"}
                 pageClassName={"page-item"}
                 pageLinkClassName={"page-link"}
                 previousClassName={"page-item"}
                 previousLinkClassName={"page-link"}
                 nextClassName={"page-item"}
                 nextLinkClassName={"page-link"}
                 breakClassName={"page-item"}
                 breakLinkClassName={"page-link"}
                 activeClassName={"active"}
             />
         </div>
     </td>
 </tr>
) : ("")}
                                                       
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Orders;