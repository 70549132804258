import React,{ useEffect } from "react";
import { Link ,useNavigate } from 'react-router-dom';
import useState from "react-usestateref";
import apiService from "../core/sevice/detail";
import { postMethod } from "../core/sevice/common.api";
import { toast } from "react-toastify";
import { setAuthorization } from "../core/sevice/axios";

function Register() {

    const initialFormValue = {
        email: "",
        password: "",
        confirmPassword: "",
      }

      const [validationnErr, setvalidationnErr] = useState("");
      const [formValue, setFormValue] = useState(initialFormValue);
      const [emailValidate, setemailValidate,emailValidateref] = useState(false);
      const [passwordValidate, setpasswordValidate,passwordValidateref] = useState(false);
      const [confirmPasswordValidate, setconfirmPasswordValidate,confirmPasswordValidateref] = useState(false);
  
      const [passHide, setPasshide] = useState(false);
      const [confPassHide, setconfPasshide] = useState(false);
      const [inputType, setInputType] = useState("password");
      const [confInputType, setconfInputType] = useState("password");

      const {
        email,
        password,
        confirmPassword,
      } = formValue;
  
      const handleChange = async (e) => {
        e.preventDefault();
        const {name, value} = e.target;
        let formData = {...formValue, ...{[name]: value}};
        setFormValue(formData);
        validate(formData);
      };

      const validate = async (values) => {
        const errors = {};
  
        if (!values.email) {
          errors.email = "Email is a required field !";
          setemailValidate(true);
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
          errors.email = "Invalid email address";
          setemailValidate(true);
        } else {
          setemailValidate(false);
        }
  
        if (!values.password) {
          errors.password = "Password is a required field !";
          setpasswordValidate(true);
        } else if (!values.password.match(/[a-z]/g)) {
          setpasswordValidate(true);
          errors.password = "Please enter at least one lower character";
        } else if (!values.password.match(/[A-Z]/g)) {
          setpasswordValidate(true);
          errors.password = "Please enter at least one upper character";
        } else if (!values.password.match(/[0-9]/g)) {
          setpasswordValidate(true);
          errors.password = "Please enter at least one digit";
        } else if (!values.password.match(/[!@#$%^&*(),.?":{}|<>]/g)) {
          setpasswordValidate(true);
          errors.password = "Please enter at least one special character";
        } else if (values.password.length < 6) {
          setpasswordValidate(true);
          errors.password = "Atleast enter 6 letters";
        } else if (values.password.length > 15) {
          setpasswordValidate(true);
          errors.password = "Only enter 15 letters";
        } else {
          setpasswordValidate(false);
        }
  
        if (!values.confirmPassword) {
          errors.confirmPassword = "Confirm password is a required field !";
          setconfirmPasswordValidate(true);
        } else if (
          values.password &&
          values.confirmPassword &&
          values.password !== values.confirmPassword
        ) {
          errors.confirmPassword = "Password and confirm password does not match";
          setconfirmPasswordValidate(true);
        } else {
          setconfirmPasswordValidate(false);
        }
    
        setvalidationnErr(errors);
        return errors;
      }

      const passwordHide = (data) => {
        if (data == "hide") {
          setPasshide(true);
          setInputType("text");
        } else {
          setPasshide(false);
          setInputType("password");
        }
      };
      const confirmPasswordHide = (data) => {
        if (data == "hide") {
          setconfPasshide(true);
          setconfInputType("text");
        } else {
          setconfPasshide(false);
          setconfInputType("password");
        }
      };

      const submit = async () => {
        try {
            validate(formValue);
            console.log("its came");
        } catch (error) {
        }
      }


  return (
    <div className="login-back">
       <div className="landing-header1 p-4">
        <Link to="/dashboard">
                <img
                src={require("../images/Logo.png")}
                alt="logo"
                className="logo-img"
                />
            </Link>
       </div>
       <div className="container">
        <div className="row">
          <div className="col-lg-6 d-flex justify-content-center">
            <div className="login-card">
              <div className="login-inner-first">
                <div className="login-inner-top">
                  <span className="login-head"> Sign Up</span>
                  <div className="login-inner-main">
                    <div className="login-inneronr-main">
                      <div className="login-main">
                        <div className="login-main-one mt-2">
                          <div className="login-email-flex">
                            <span className="login-email">E-mail</span>
                            <input
                              type="text"
                              placeholder="Enter your mail address"
                              className="login-input mt-1"
                              name="email"
                              value={email}
                              onChange={handleChange}
                            />
                          </div>
                          {emailValidate == true ? (
                            <p className="errorcss">
                              {" "}
                              {validationnErr.email}{" "}
                            </p>
                          ) : (
                            ""
                          )}
                        
                          <div className="login-email-flex">
                            <span className="login-email">Password</span>
                            <div className="flex_input_posion">
                              <input
                                placeholder="Enter your password"
                                className="login-input mt-1"
                                type={inputType}
                                name="password"
                                value={password}
                                onChange={handleChange}
                                maxLength={40}
                              />
                                {passHide == false ? (
                                    <i class="fa-regular fa-eye-slash login-pass-eye"
                                     onClick={() => passwordHide("hide")}
                                     ></i>
                                ) : (
                                    <i class="fa-regular fa-eye login-pass-eye"  onClick={() => passwordHide("show")}></i>
                           
                                )} 
                          
                            </div>
                          </div>
                          {passwordValidate == true ? (
                            <p className="errorcss">
                              {" "}
                              {validationnErr.password}{" "}
                            </p>
                          ) : (
                            ""
                          )}
                        
                            <div className="login-email-flex">
                            <span className="login-email">Confirm Password</span>
                            <div className="flex_input_posion">
                              <input
                                placeholder="Re-Enter your password"
                                className="login-input mt-1"
                                type={confInputType}
                                name="confirmPassword"
                                value={confirmPassword}
                                onChange={handleChange}
                                maxLength={40}
                              />
                               {confPassHide == false ? (
                                    <i
                                    className="fa-regular fa-eye-slash login-pass-eye"
                                    onClick={() => confirmPasswordHide("hide")}
                                    ></i>
                                ) : (
                                    <i
                                    className="fa-regular fa-eye login-pass-eye"
                                    onClick={() => confirmPasswordHide("show")}
                                    ></i>
                                )}
                          
                            </div>
                          </div>
                          {confirmPasswordValidate == true ? (
                            <p className="errorcss">
                              {" "}
                              {validationnErr.confirmPassword}{" "}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>

                        <div class="or-line-container my-3">
                          <div class="or-line"></div>
                          <div class="or-text">or</div>
                          <div class="or-line"></div>
                        </div>
                      </div>

                      <div className="login-google">
                        <img
                          src={require("../images/Google.png")}
                          alt="google image"
                        />
                        <span className="continue-google">
                          {" "}
                          Continue with Google
                        </span>
                      </div>
                    </div>

                    <div>
                   
                         <button className="login-submit-btn mt-4" onClick={submit}>
                         <span className="login-submit">Sign Up</span>
                       </button>
                  
                     
                    </div>
                  </div>
                  <div className="login-sign-center">
                    <div className="mt-2 mx-5">
                    <span className="login-footer">
                    By clicking “Sign Up” or “Continue with Google”, you agree to the BLF-Club’s
                    <span className="login-create"> Terms & Conditions</span> and <span className="login-create">Privacy Policy.</span>
                    </span>
                    </div>
                   <div className="mt-2">
                   <span className="login-footer">
                       Already have an account ?
                        <Link to="/">
                          <span className="login-create"> Sign In.</span>
                        </Link>
                    </span>
                   </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Register;