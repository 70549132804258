import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import Sidebar from "./Sidebar";
import ReactPaginate from "react-paginate";
import { socket } from "../core/context/socket";

function TradeOpportunity() {
  const [opportunities, setopportunities, opportunitiesref] = useState([]);
  const [opportunities2, setopportunities2, opportunitiesref2] = useState([]);
  const [opportunities3, setopportunities3, opportunitiesref3] = useState([]);
  const [opportunities4, setopportunities4, opportunitiesref4] = useState([]);
  const [isLoader, setIsLoader] = useState(false);

  useEffect(() => {
    let socket_token = localStorage.getItem("socket_token");
    let socketsplit = socket_token.split("_");
    socket.connect();
    socket.emit("GetProfitAllData");
    setIsLoader(true);
    socket.on("ProfitData", async (response) => {
      
      //console.log("profit data", response);
      if (response != null) {
        if (response.data.length > 0) {
          var opportunity_data = response.data.slice(0,4);
          setopportunities(opportunity_data);

          var opportunity_data2 = response.data.slice(4,8);
          setopportunities2(opportunity_data2);

          var opportunity_data3 = response.data.slice(8,12);
          setopportunities3(opportunity_data3);

          var opportunity_data4 = response.data.slice(12,16);
          setopportunities4(opportunity_data4);

          setIsLoader(false);
        } else {
          setIsLoader(false);
        }
      }
    });
  }, [0]);

  useEffect(() => {
    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <div>
      <Header />
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2 col-sm-3">
            <Sidebar />
          </div>
          <div className="col-lg-10">
          {isLoader == true ? (
                <>
                 <div className="loader-css">
                  <img
                      src={require("../images/Dual Ball@1x-1.0s-200px-200px.gif")}
                      alt="loader"
                      className="loader-gif"
                  />
                  </div>
                </>
              ):( 
            <div className="container-fluid dashboard-container">
              <span className="dashboard-portfolio">
                {" "}
                Arbitrage Opportunities
              </span>
              <div className="row pt-4">
              {isLoader == true ? (
                <>
                 <div className="loader-css">
                  <img
                      src={require("../images/Dual Ball@1x-1.0s-200px-200px.gif")}
                      alt="loader"
                      className="loader-gif"
                  />
                  </div>
                </>
              ):( 
                <>
              {opportunitiesref.current.length > 0 ? (
                    opportunitiesref.current.map((element, index) => {

                    return (
                     <>
                     
                     <div className="col-lg-3 col-sm-6">
                  <div className="opportunities_card">
                    <h4>
                    {element.pair} <span>+{parseFloat(element.profit_percent).toFixed(4)}%</span>
                    </h4>
                    <div className="opportunities_card_coins">
                      <div className="">
                        <img
                          src={require(`../images/exchange/${element.bid_exchange.toLowerCase()}.png`)}
                          className="bnb_coin"
                        />
                        <p>${element.bid_price}</p>
                      </div>
                      <div className="swap_img">
                        <img src={require("../images/swap_icon.png")} />
                      </div>
                      <div className="">
                        <img
                          src={require(`../images/exchange/${element.ask_exchange.toLowerCase()}.png`)}
                          className="bnb_coin"
                        />
                        <p>${element.ask_price}</p>
                      </div>
                    </div>
                    <div className="price_content">
                      <p>Price:</p>
                      <h5>${parseFloat(element.difference).toFixed(6)}</h5>
                    </div>
                  </div>
                </div>

                     </>
                    );
                    })
                    ) : (
                      ""
                    )}
                    </>
                )}
                
                {/* <div className="col-lg-3 col-sm-6">
                  <div className="opportunities_card">
                    <h4>
                      BTC/USDT <span>+75.0%</span>
                    </h4>
                    <div className="opportunities_card_coins">
                      <div className="">
                        <img
                          src={require("../images/bnb_coin.png")}
                          className="bnb_coin"
                        />
                        <p>$932.55</p>
                      </div>
                      <div className="swap_img">
                        <img src={require("../images/swap_icon.png")} />
                      </div>
                      <div className="">
                        <img
                          src={require("../images/daul_coin.png")}
                          className="bnb_coin"
                        />
                        <p>$964.15</p>
                      </div>
                    </div>
                    <div className="price_content">
                      <p>Price:</p>
                      <h5>$31.600000</h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="opportunities_card">
                    <h4>
                      BTC/USDT <span>+75.0%</span>
                    </h4>
                    <div className="opportunities_card_coins">
                      <div className="">
                        <img
                          src={require("../images/bnb_coin.png")}
                          className="bnb_coin"
                        />
                        <p>$932.55</p>
                      </div>
                      <div className="swap_img">
                        <img src={require("../images/swap_icon.png")} />
                      </div>
                      <div className="">
                        <img
                          src={require("../images/daul_coin.png")}
                          className="bnb_coin"
                        />
                        <p>$964.15</p>
                      </div>
                    </div>
                    <div className="price_content">
                      <p>Price:</p>
                      <h5>$31.600000</h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="opportunities_card">
                    <h4>
                      BTC/USDT <span>+75.0%</span>
                    </h4>
                    <div className="opportunities_card_coins">
                      <div className="">
                        <img
                          src={require("../images/bnb_coin.png")}
                          className="bnb_coin"
                        />
                        <p>$932.55</p>
                      </div>
                      <div className="swap_img">
                        <img src={require("../images/swap_icon.png")} />
                      </div>
                      <div className="">
                        <img
                          src={require("../images/daul_coin.png")}
                          className="bnb_coin"
                        />
                        <p>$964.15</p>
                      </div>
                    </div>
                    <div className="price_content">
                      <p>Price:</p>
                      <h5>$31.600000</h5>
                    </div>
                  </div>
                </div> */}
              </div>

              <div className="row pt-5">
              {isLoader == true ? (
                <>
                 <div className="loader-css">
                  <img
                      src={require("../images/Dual Ball@1x-1.0s-200px-200px.gif")}
                      alt="loader"
                      className="loader-gif"
                  />
                  </div>
                </>
              ):( 
                <>
              {opportunitiesref2.current.length > 0 ? (
                    opportunitiesref2.current.map((element, index) => {

                    return (
                     <>
                     
                    <div className="col-lg-3 col-sm-6">
                  <div className="opportunities_card">
                    <h4>
                    {element.pair} <span>+{parseFloat(element.profit_percent).toFixed(4)}%</span>
                    </h4>
                    <div className="opportunities_card_coins">
                      <div className="">
                        <img
                          src={require(`../images/exchange/${element.bid_exchange.toLowerCase()}.png`)}
                          className="bnb_coin"
                        />
                        <p>${element.bid_price}</p>
                      </div>
                      <div className="swap_img">
                        <img src={require("../images/swap_icon.png")} />
                      </div>
                      <div className="">
                        <img
                          src={require(`../images/exchange/${element.ask_exchange.toLowerCase()}.png`)}
                          className="bnb_coin"
                        />
                        <p>${element.ask_price}</p>
                      </div>
                    </div>
                    <div className="price_content">
                      <p>Price:</p>
                      <h5>${parseFloat(element.difference).toFixed(6)}</h5>
                    </div>
                  </div>
                </div>

                     </>
                    );
                    })
                    ) : (
                      ""
                    )}
                    </>
                )}
              </div>

              <div className="row pt-5">
              {isLoader == true ? (
                <>
                 <div className="loader-css">
                  <img
                      src={require("../images/Dual Ball@1x-1.0s-200px-200px.gif")}
                      alt="loader"
                      className="loader-gif"
                  />
                  </div>
                </>
              ):( 
                <>
              {opportunitiesref3.current.length > 0 ? (
                    opportunitiesref3.current.map((element, index) => {

                    return (
                     <>
                     
                    <div className="col-lg-3 col-sm-6">
                  <div className="opportunities_card">
                    <h4>
                    {element.pair} <span>+{parseFloat(element.profit_percent).toFixed(4)}%</span>
                    </h4>
                    <div className="opportunities_card_coins">
                      <div className="">
                        <img
                          src={require(`../images/exchange/${element.bid_exchange.toLowerCase()}.png`)}
                          className="bnb_coin"
                        />
                        <p>${element.bid_price}</p>
                      </div>
                      <div className="swap_img">
                        <img src={require("../images/swap_icon.png")} />
                      </div>
                      <div className="">
                        <img
                          src={require(`../images/exchange/${element.ask_exchange.toLowerCase()}.png`)}
                          className="bnb_coin"
                        />
                        <p>${element.ask_price}</p>
                      </div>
                    </div>
                    <div className="price_content">
                      <p>Price:</p>
                      <h5>${parseFloat(element.difference).toFixed(6)}</h5>
                    </div>
                  </div>
                </div>

                     </>
                    );
                    })
                    ) : (
                      ""
                    )}
                    </>
                )}
              </div>

              <div className="row pt-5">
              {isLoader == true ? (
                <>
                 <div className="loader-css">
                  <img
                      src={require("../images/Dual Ball@1x-1.0s-200px-200px.gif")}
                      alt="loader"
                      className="loader-gif"
                  />
                  </div>
                </>
              ):( 
                <>
              {opportunitiesref4.current.length > 0 ? (
                    opportunitiesref4.current.map((element, index) => {

                    return (
                     <>
                     
                    <div className="col-lg-3 col-sm-6">
                  <div className="opportunities_card">
                    <h4>
                    {element.pair} <span>+{parseFloat(element.profit_percent).toFixed(4)}%</span>
                    </h4>
                    <div className="opportunities_card_coins">
                      <div className="">
                        <img
                          src={require(`../images/exchange/${element.bid_exchange.toLowerCase()}.png`)}
                          className="bnb_coin"
                        />
                        <p>${element.bid_price}</p>
                      </div>
                      <div className="swap_img">
                        <img src={require("../images/swap_icon.png")} />
                      </div>
                      <div className="">
                        <img
                          src={require(`../images/exchange/${element.ask_exchange.toLowerCase()}.png`)}
                          className="bnb_coin"
                        />
                        <p>${element.ask_price}</p>
                      </div>
                    </div>
                    <div className="price_content">
                      <p>Price:</p>
                      <h5>${parseFloat(element.difference).toFixed(6)}</h5>
                    </div>
                  </div>
                </div>

                     </>
                    );
                    })
                    ) : (
                      ""
                    )}
                    </>
                )}
              </div>
              {/* <div className="row pt-5">
                <div className="col-lg-3 col-sm-6">
                  <div className="opportunities_card">
                    <h4>
                      BTC/USDT <span>+75.0%</span>
                    </h4>
                    <div className="opportunities_card_coins">
                      <div className="">
                        <img
                          src={require("../images/bnb_coin.png")}
                          className="bnb_coin"
                        />
                        <p>$932.55</p>
                      </div>
                      <div className="swap_img">
                        <img src={require("../images/swap_icon.png")} />
                      </div>
                      <div className="">
                        <img
                          src={require("../images/daul_coin.png")}
                          className="bnb_coin"
                        />
                        <p>$964.15</p>
                      </div>
                    </div>
                    <div className="price_content">
                      <p>Price:</p>
                      <h5>$31.600000</h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="opportunities_card">
                    <h4>
                      BTC/USDT <span>+75.0%</span>
                    </h4>
                    <div className="opportunities_card_coins">
                      <div className="">
                        <img
                          src={require("../images/bnb_coin.png")}
                          className="bnb_coin"
                        />
                        <p>$932.55</p>
                      </div>
                      <div className="swap_img">
                        <img src={require("../images/swap_icon.png")} />
                      </div>
                      <div className="">
                        <img
                          src={require("../images/daul_coin.png")}
                          className="bnb_coin"
                        />
                        <p>$964.15</p>
                      </div>
                    </div>
                    <div className="price_content">
                      <p>Price:</p>
                      <h5>$31.600000</h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="opportunities_card">
                    <h4>
                      BTC/USDT <span>+75.0%</span>
                    </h4>
                    <div className="opportunities_card_coins">
                      <div className="">
                        <img
                          src={require("../images/bnb_coin.png")}
                          className="bnb_coin"
                        />
                        <p>$932.55</p>
                      </div>
                      <div className="swap_img">
                        <img src={require("../images/swap_icon.png")} />
                      </div>
                      <div className="">
                        <img
                          src={require("../images/daul_coin.png")}
                          className="bnb_coin"
                        />
                        <p>$964.15</p>
                      </div>
                    </div>
                    <div className="price_content">
                      <p>Price:</p>
                      <h5>$31.600000</h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="opportunities_card">
                    <h4>
                      BTC/USDT <span>+75.0%</span>
                    </h4>
                    <div className="opportunities_card_coins">
                      <div className="">
                        <img
                          src={require("../images/bnb_coin.png")}
                          className="bnb_coin"
                        />
                        <p>$932.55</p>
                      </div>
                      <div className="swap_img">
                        <img src={require("../images/swap_icon.png")} />
                      </div>
                      <div className="">
                        <img
                          src={require("../images/daul_coin.png")}
                          className="bnb_coin"
                        />
                        <p>$964.15</p>
                      </div>
                    </div>
                    <div className="price_content">
                      <p>Price:</p>
                      <h5>$31.600000</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row pt-5">
                <div className="col-lg-3 col-sm-6">
                  <div className="opportunities_card">
                    <h4>
                      BTC/USDT <span>+75.0%</span>
                    </h4>
                    <div className="opportunities_card_coins">
                      <div className="">
                        <img
                          src={require("../images/bnb_coin.png")}
                          className="bnb_coin"
                        />
                        <p>$932.55</p>
                      </div>
                      <div className="swap_img">
                        <img src={require("../images/swap_icon.png")} />
                      </div>
                      <div className="">
                        <img
                          src={require("../images/daul_coin.png")}
                          className="bnb_coin"
                        />
                        <p>$964.15</p>
                      </div>
                    </div>
                    <div className="price_content">
                      <p>Price:</p>
                      <h5>$31.600000</h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="opportunities_card">
                    <h4>
                      BTC/USDT <span>+75.0%</span>
                    </h4>
                    <div className="opportunities_card_coins">
                      <div className="">
                        <img
                          src={require("../images/bnb_coin.png")}
                          className="bnb_coin"
                        />
                        <p>$932.55</p>
                      </div>
                      <div className="swap_img">
                        <img src={require("../images/swap_icon.png")} />
                      </div>
                      <div className="">
                        <img
                          src={require("../images/daul_coin.png")}
                          className="bnb_coin"
                        />
                        <p>$964.15</p>
                      </div>
                    </div>
                    <div className="price_content">
                      <p>Price:</p>
                      <h5>$31.600000</h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="opportunities_card">
                    <h4>
                      BTC/USDT <span>+75.0%</span>
                    </h4>
                    <div className="opportunities_card_coins">
                      <div className="">
                        <img
                          src={require("../images/bnb_coin.png")}
                          className="bnb_coin"
                        />
                        <p>$932.55</p>
                      </div>
                      <div className="swap_img">
                        <img src={require("../images/swap_icon.png")} />
                      </div>
                      <div className="">
                        <img
                          src={require("../images/daul_coin.png")}
                          className="bnb_coin"
                        />
                        <p>$964.15</p>
                      </div>
                    </div>
                    <div className="price_content">
                      <p>Price:</p>
                      <h5>$31.600000</h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="opportunities_card">
                    <h4>
                      BTC/USDT <span>+75.0%</span>
                    </h4>
                    <div className="opportunities_card_coins">
                      <div className="">
                        <img
                          src={require("../images/bnb_coin.png")}
                          className="bnb_coin"
                        />
                        <p>$932.55</p>
                      </div>
                      <div className="swap_img">
                        <img src={require("../images/swap_icon.png")} />
                      </div>
                      <div className="">
                        <img
                          src={require("../images/daul_coin.png")}
                          className="bnb_coin"
                        />
                        <p>$964.15</p>
                      </div>
                    </div>
                    <div className="price_content">
                      <p>Price:</p>
                      <h5>$31.600000</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row pt-5">
                <div className="col-lg-3 col-sm-6">
                  <div className="opportunities_card">
                    <h4>
                      BTC/USDT <span>+75.0%</span>
                    </h4>
                    <div className="opportunities_card_coins">
                      <div className="">
                        <img
                          src={require("../images/bnb_coin.png")}
                          className="bnb_coin"
                        />
                        <p>$932.55</p>
                      </div>
                      <div className="swap_img">
                        <img src={require("../images/swap_icon.png")} />
                      </div>
                      <div className="">
                        <img
                          src={require("../images/daul_coin.png")}
                          className="bnb_coin"
                        />
                        <p>$964.15</p>
                      </div>
                    </div>
                    <div className="price_content">
                      <p>Price:</p>
                      <h5>$31.600000</h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="opportunities_card">
                    <h4>
                      BTC/USDT <span>+75.0%</span>
                    </h4>
                    <div className="opportunities_card_coins">
                      <div className="">
                        <img
                          src={require("../images/bnb_coin.png")}
                          className="bnb_coin"
                        />
                        <p>$932.55</p>
                      </div>
                      <div className="swap_img">
                        <img src={require("../images/swap_icon.png")} />
                      </div>
                      <div className="">
                        <img
                          src={require("../images/daul_coin.png")}
                          className="bnb_coin"
                        />
                        <p>$964.15</p>
                      </div>
                    </div>
                    <div className="price_content">
                      <p>Price:</p>
                      <h5>$31.600000</h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="opportunities_card">
                    <h4>
                      BTC/USDT <span>+75.0%</span>
                    </h4>
                    <div className="opportunities_card_coins">
                      <div className="">
                        <img
                          src={require("../images/bnb_coin.png")}
                          className="bnb_coin"
                        />
                        <p>$932.55</p>
                      </div>
                      <div className="swap_img">
                        <img src={require("../images/swap_icon.png")} />
                      </div>
                      <div className="">
                        <img
                          src={require("../images/daul_coin.png")}
                          className="bnb_coin"
                        />
                        <p>$964.15</p>
                      </div>
                    </div>
                    <div className="price_content">
                      <p>Price:</p>
                      <h5>$31.600000</h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="opportunities_card">
                    <h4>
                      BTC/USDT <span>+75.0%</span>
                    </h4>
                    <div className="opportunities_card_coins">
                      <div className="">
                        <img
                          src={require("../images/bnb_coin.png")}
                          className="bnb_coin"
                        />
                        <p>$932.55</p>
                      </div>
                      <div className="swap_img">
                        <img src={require("../images/swap_icon.png")} />
                      </div>
                      <div className="">
                        <img
                          src={require("../images/daul_coin.png")}
                          className="bnb_coin"
                        />
                        <p>$964.15</p>
                      </div>
                    </div>
                    <div className="price_content">
                      <p>Price:</p>
                      <h5>$31.600000</h5>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TradeOpportunity;
