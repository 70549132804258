import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import apiService from "../core/sevice/detail";
import { postMethod } from "../core/sevice/common.api";
import { toast } from "react-toastify";
import { setAuthorization } from "../core/sevice/axios";

function Forget() {
  const navigate = useNavigate();

  const initialFormValue = {
    email: "",
  };

  const initialFormValue1 = {
    password: "",
    confirmPassword: "",
  };

  const [emailValidate, setemailValidate, emailValidateref] = useState(false);
  const [validationnErr, setvalidationnErr] = useState("");
  const [formValue, setFormValue] = useState(initialFormValue);
  const [buttonLoader, setbuttonLoader] = useState(false);

  const [passwordValidate, setpasswordValidate, passwordValidateref] =
    useState(false);
  const [formValue1, setFormValue1] = useState(initialFormValue1);
  const [
    confirmPasswordValidate,
    setconfirmPasswordValidate,
    confirmPasswordValidateref,
  ] = useState(false);
  const [dpliutl, setdpliutl] = useState("");
  const [activeStatus, seractiveStatus] = useState(false);
  const [validationnErr1, setvalidationnErr1] = useState("");
  const [passHide, setPasshide] = useState(false);
  const [inputType, setinputType] = useState("password");
  const [passHidconf, setPasshideconf] = useState(false);
  const [inputTypeconf, setinputTypeconf] = useState("password");

  const { email } = formValue;
  const { password, confirmPassword } = formValue1;

  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    validate(formData);
  };

  const handleChange1 = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData1 = { ...formValue1, ...{ [name]: value } };
    setFormValue1(formData1);
    validate1(formData1);
  };

  useEffect(() => {
    linkverify();
  }, [0]);

  const linkverify = async (values) => {
    var dpliutl = window.location.href.split("_")[1];
    if (dpliutl) {
      setdpliutl(dpliutl);
      var obj = {
        link: dpliutl,
      };
      var data = {
        apiUrl: apiService.adminverifyForgotpasslink,
        payload: obj,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      setbuttonLoader(false);
      if (resp.status) {
        seractiveStatus(true);
        toast.success(resp.Message);
      } else {
        toast.error(resp.Message);
      }
    } else {
    }
  };

  const validate = async (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Email is a required field !";
      setemailValidate(true);
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address";
      setemailValidate(true);
    } else {
      setemailValidate(false);
    }

    setvalidationnErr(errors);
    return errors;
  };

  const validate1 = async (values) => {
    const errors1 = {};

    if (values.password == "") {
      setpasswordValidate(true);
      errors1.password = "Password is required !";
    } else if (!values.password.match(/[a-z]/g)) {
      setpasswordValidate(true);
      errors1.password = "Please enter at least one lower character";
    } else if (!values.password.match(/[A-Z]/g)) {
      setpasswordValidate(true);
      errors1.password = "Please enter at least one upper character";
    } else if (!values.password.match(/[0-9]/g)) {
      setpasswordValidate(true);
      errors1.password = "Please enter at least one digit";
    } else if (!values.password.match(/[!@#$%^&*(),.?":{}|<>]/g)) {
      setpasswordValidate(true);
      errors1.password = "Please enter at least one special character";
    } else if (values.password.length < 6) {
      setpasswordValidate(true);
      errors1.password = "Atleast enter 6 letters";
    } else if (values.password.length > 15) {
      setpasswordValidate(true);
      errors1.password = "Only enter 15 letters";
    } else {
      setpasswordValidate(false);
    }

    if (!values.confirmPassword) {
      errors1.confirmPassword = "Confirm password is a required field !";
      setconfirmPasswordValidate(true);
    } else if (
      values.password &&
      values.confirmPassword &&
      values.password !== values.confirmPassword
    ) {
      // errors.password = 'Password and confirm password does not match';
      errors1.confirmPassword = "Password and Confirm password does not match";
      setconfirmPasswordValidate(true);
    } else {
      setconfirmPasswordValidate(false);
    }

    setvalidationnErr1(errors1);
    return errors1;
  };

  const formSubmit = async () => {
    validate(formValue);
    if (formValue.email != "" && emailValidateref.current == false) {
      var obj = {
        email: formValue.email,
      };
      var data = {
        apiUrl: apiService.adminchangepswdlink,
        payload: obj,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      console.log(resp, "==-=-resp");
      setbuttonLoader(false);
      if (resp.status) {
        toast.success(resp.message);
      } else {
        toast.error(resp.message);
      }
    }
  };

  const formSubmitchange = async () => {
    validate1(formValue1);
    if (
      passwordValidateref.current === false &&
      confirmPasswordValidateref.current === false
    ) {
      var obj = {
        password: formValue1.password,
        confimPassword: formValue1.confirmPassword,
        id: dpliutl,
      };

      console.log(obj, "=-=-=-=-=-=-=-==-=");
      var data = {
        apiUrl: apiService.adminresetpassword,
        payload: obj,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      setbuttonLoader(false);
      setFormValue1(initialFormValue1);
      if (resp.status) {
        toast.success(resp.Message);
        navigate("/");
      } else {
        toast.error(resp.Message);
      }
    }
  };

  const passwordHide = (data) => {
    if (data == "hide") {
      setPasshide(true);
      setinputType("text");
    } else {
      setPasshide(false);
      setinputType("password");
    }
  };

  const passwordHideconf = (data) => {
    if (data == "hide") {
      setPasshideconf(true);
      setinputTypeconf("text");
    } else {
      setPasshideconf(false);
      setinputTypeconf("password");
    }
  };

  return (
    <div className="login-back">
      <div className="landing-header1 p-4">
        <Link to="/dashboard">
          <img
            src={require("../images/daulah_holding_logo.png")}
            alt="logo"
            className="logo-img"
          />
        </Link>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 d-flex justify-content-center">
            {activeStatus == false ? (
              <div className="login-card">
                <div className="login-inner-first">
                  <div className="login-inner-top">
                    <span className="login-head"> Forget Password </span>
                    <div className="login-inner-main">
                      <div className="login-inneronr-main">
                        <div className="login-main">
                          <div className="login-main-one mt-2">
                            <div className="login-email-flex">
                              <span className="login-email">E-mail</span>
                              <input
                                type="text"
                                placeholder="Enter your mail address"
                                className="login-input mt-1"
                                name="email"
                                value={email}
                                onChange={handleChange}
                              />
                            </div>
                            {emailValidateref.current == true ? (
                              <p className="errorcss">
                                {" "}
                                {validationnErr.email}{" "}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      <div>
                        {buttonLoader == false ? (
                          <button
                            className="login-submit-btn mt-4"
                            onClick={formSubmit}
                          >
                            <span className="login-submit">Continue</span>
                          </button>
                        ) : (
                          <button className="login-submit-btn mt-4">
                            <span className="login-submit">Loading...</span>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="login-card">
                <div className="login-inner-first">
                  <div className="login-inner-top">
                    <span className="login-head"> Reset Password</span>
                    <div className="login-inner-main">
                      <div className="login-inneronr-main">
                        <div className="login-main">
                          <div className="login-main-one mt-2">
                            <div className="login-email-flex">
                              <span className="login-email">New Password</span>
                              <div className="flex_input_posion">
                                <input
                                  placeholder="Enter your password"
                                  className="login-input mt-1"
                                  type={inputType}
                                  name="password"
                                  value={password}
                                  onChange={handleChange1}
                                  maxLength={40}
                                />
                                {passHide == false ? (
                                  <i
                                    class="fa-regular fa-eye-slash login-pass-eye"
                                    onClick={() => passwordHide("hide")}
                                  ></i>
                                ) : (
                                  <i
                                    class="fa-regular fa-eye login-pass-eye"
                                    onClick={() => passwordHide("show")}
                                  ></i>
                                )}
                              </div>
                            </div>
                            {passwordValidate == true ? (
                              <p className="errorcss">
                                {" "}
                                {validationnErr1.password}{" "}
                              </p>
                            ) : (
                              ""
                            )}

                            <div className="login-email-flex">
                              <span className="login-email">
                                Confirm Password
                              </span>
                              <div className="flex_input_posion">
                                <input
                                  placeholder="Re-Enter your password"
                                  className="login-input mt-1"
                                  type={inputTypeconf}
                                  name="confirmPassword"
                                  value={confirmPassword}
                                  onChange={handleChange1}
                                  maxLength={40}
                                />
                                {passHidconf == false ? (
                                  <i
                                    className="fa-regular fa-eye-slash login-pass-eye"
                                    onClick={() => passwordHideconf("hide")}
                                  ></i>
                                ) : (
                                  <i
                                    className="fa-regular fa-eye login-pass-eye"
                                    onClick={() => passwordHideconf("show")}
                                  ></i>
                                )}
                              </div>
                            </div>
                            {confirmPasswordValidate == true ? (
                              <p className="errorcss">
                                {" "}
                                {validationnErr1.confirmPassword}{" "}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      <div>
                        {buttonLoader == false ? (
                          <button
                            className="login-submit-btn mt-4"
                            onClick={formSubmitchange}
                          >
                            <span className="login-submit">
                              Change Password
                            </span>
                          </button>
                        ) : (
                          <button className="login-submit-btn mt-4">
                            <span className="login-submit">Loading...</span>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Forget;
