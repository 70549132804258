import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import Sidebar from "./Sidebar";
import ReactPaginate from "react-paginate";
import apiService from "../core/sevice/detail";
import { getMethod,postMethod } from "../core/sevice/common.api";
import moment from "moment";
import { socket } from "../core/context/socket";
import toast, { Toaster }  from "react-hot-toast";
import * as XLSX from 'xlsx';


function LoginHistory() {
  const [buttonLoader, setButtonLoader] = useState(false);
  const [adminDetails, setAdminDetails, adminDetailsref] = useState("");
  const [adminAllDetails, setadminAllDetails, adminAllDetailsref] = useState("");
  const [loginCounts, setloginCounts, loginCountsref] = useState(0);

  useEffect(() => {
    getLoginHistory(1);
  }, []);

  const getLoginHistory = async (currentPage) => {
    let count = currentPage == undefined ? 1 : currentPage;
    try {
      var payload = {
        perPage: 10,
        page: count,
      };
      var data = {
        apiUrl: apiService.getadminlogin,
        payload: payload,
      };
      setButtonLoader(true);
      var resp = await postMethod(data);
      setButtonLoader(false);
      if (resp) {
        console.log(resp, "admin login details");
        setAdminDetails(resp.data);
        setloginCounts(resp.count);
      }
    } catch (error) {
      //   setIsLoading(false);
    }
  };

  const handlePageClick_trade = async (data) => {
    console.log(data.selected);
    let currentPage = data.selected + 1;
    getLoginHistory(currentPage);
  };

  const downloadData = async () => {
    try {
      var data = {
        apiUrl: apiService.getalladminlogin,
      };
      var resp = await getMethod(data);
      if(resp.status == true) {
        setadminAllDetails(resp.data);
        const fieldsToExport = ['date', 'OS', 'ipAddress','platform','browser'];
        // console.log("Admin Details:", adminAllDetailsref.current);
        const filteredData = adminAllDetailsref.current.map(item => {
          const filteredItem = {};
          fieldsToExport.forEach(field => {
            filteredItem[field] = item[field];
          });
          // console.log("Filtered Item:", filteredItem);
            return filteredItem;
        })
        // console.log("Filtered Data:", filteredData);
        const worksheet = XLSX.utils.json_to_sheet(filteredData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'LoginHistory.xlsx');
  
        toast.success("File Downloaded")
      }
    } catch (error) {
      
    }
  }

  return (
    <div>
               <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          duration: 4000,
        }}
      />
      <Header />
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2 col-sm-3">
            <Sidebar />
          </div>
          <div className="col-lg-10 col-sm-9">
            <div className="container dashboard-container">
              <div className="profit_main_change">
                <div className="profit-head">
                  <span className="dashboard-portfolio">Login History</span>
                  <div className="profit_top_rght">
                    {/* <div className="order-search">
                      <input
                        type="text"
                        placeholder="Search"
                        className="order-srch-input"
                        // onChange={(e) => setsearch_trade(e.target.value)}
                      />
                      <svg
                        // onClick={searchTradeOrders}
                        xmlns="http://www.w3.org/2000/svg"
                        className="search-icon"
                        viewBox="0 0 96 96"
                        id="Search"
                      >
                        <switch>
                          <g fill="#8a8b8c" class="color000000 svgShape">
                            <path
                              d="M90.829 85.172 68.128 62.471A35.846 35.846 0 0 0 76 40C76 20.118 59.883 4 40 4 20.118 4 4 20.118 4 40s16.118 36 36 36c8.5 0 16.312-2.946 22.471-7.873l22.701 22.701A3.988 3.988 0 0 0 88 92a4 4 0 0 0 2.829-6.828zM40 68c-15.464 0-28-12.536-28-28s12.536-28 28-28c15.465 0 28 12.536 28 28S55.465 68 40 68z"
                              fill="#8a8b8c"
                              class="color000000 svgShape"
                            ></path>
                          </g>
                        </switch>
                      </svg>
                    </div> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="dash-bottom-img"
                      viewBox="0 0 32 32"
                      id="download"
                      onClick={downloadData}
                    >
                      <g fill="#8a8b8c" class="color000000 svgShape">
                        <path
                          d="M25,19a1,1,0,0,0-1,1v5H8V20a1,1,0,0,0-2,0v5.14A1.93,1.93,0,0,0,8,27H24a1.93,1.93,0,0,0,2-1.86V20A1,1,0,0,0,25,19Z"
                          fill="#8a8b8c"
                          class="color000000 svgShape"
                        ></path>
                        <path
                          d="M15.27,20.68l0,0a1.2,1.2,0,0,0,.26.18l0,0h0A1,1,0,0,0,16,21a1,1,0,0,0,.38-.08l.12-.07a1.13,1.13,0,0,0,.18-.12l0,0,0,0,5-5.38a1,1,0,1,0-1.46-1.37L17,17.45V6a1,1,0,0,0-2,0V17.45l-3.27-3.52a1,1,0,1,0-1.46,1.37Z"
                          fill="#8a8b8c"
                          class="color000000 svgShape"
                        ></path>
                      </g>
                    </svg>
                  </div>
                </div>
                <div class="table-responsive">
                  <table className="market-table mt-5">
                    <thead className="market-head">
                      <th> Date & Time</th>
                      <th>OS</th>
                      <th> IP Address</th>
                      <th>Device</th>
                      <th>Browser</th>
                      <th>Status</th>
                    </thead>
                    <tbody>
                      {/* {isLoadingref.current == true ? (
                                            <>
                                            <tr className='text-center'>
                                                <td colSpan={12}>
                                                <div className="loader-css">
                                                <img
                                                    src={require("../images/Dual Ball@1x-1.0s-200px-200px.gif")}
                                                    alt="loader"
                                                    className="loader-gif"
                                                />
                                                </div>
                                                </td>
                                            </tr>
                                            
                                            </>
                                            ):(
                                            <>
                                            {tradeOrdersref.current.length > 0 ? (
                                            tradeOrdersref.current.map((element, index) => {
                                                var strategy = (element.strategy == "intra" || element.strategy == "dca") ? "Intra": element.strategy;
                                            return (
                                            <>
                                            <tr>
                                                <td>
                                                    <div className='order-place-frst'>
                                                        <span className='order-place-inner'>{element.pair}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span className='dash-btm-price'>{element.amount}</span>
                                                </td>
                                                <td>
                                                    <span className='dash-btm-price'>{element.entry_price}</span>
                                                </td>
                                                <td>
                                                    <span className='dash-btm-price'>{element.exit_price}</span>
                                                </td>
                                                <td>
                                                    <span className='dash-btm-date'>{element.entry_total}</span>
                                                </td>
                                                <td>
                                                    <span className='dash-btm-date'>{element.exit_total}</span>
                                                </td>
                                                <td>
                                                    <span className='dash-btm-date'>{element.profit_amount}</span>
                                                </td>
                                                <td>
                                                    <span className='dash-btm-date'>{element.profit_percentage}</span>
                                                </td>
                                                <td>
                                                    <span className='dash-btm-date'>{element.buy_exchange}</span>
                                                </td>
                                                <td>
                                                    <span className='dash-btm-date'>{element.sell_exchange}</span>
                                                </td>
                                                <td>
                                                    <span className='dash-btm-date'>{strategy}</span>
                                                </td>
                                                <td>
                                                    <span className='dash-btm-date'>{element.date}</span>
                                                </td>
                                                </tr>
                                                </>
                                                )
                                                })) : (
                                                <tr className='text-center no-data-common'>
                                                    <td colSpan={12} className='p-5'>
                                                    No Datas Found
                                                    </td>
                                                </tr>
                                                )}
                                                </>
                                            )} */}

                      {buttonLoader == true ? (
                        <>
                          <tr className="text-center">
                            <td colSpan={6}>
                              <div className="loader-css">
                                <img
                                  src={require("../images/Dual Ball@1x-1.0s-200px-200px.gif")}
                                  alt="loader"
                                  className="loader-gif"
                                />
                              </div>
                            </td>
                          </tr>
                        </>
                      ) : (
                        <>
                          {adminDetailsref.current.length > 0 ? (
                            <>
                              {adminDetailsref.current.map((item) => (
                                <tr>
                                  <td>
                                    <span className="dash-coin-name">
                                      {item.date}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="dash-coin-name">
                                      {" "}
                                      {item.OS}{" "}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="dash-coin-name">
                                      {" "}
                                      {item.ipAddress}{" "}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="dash-coin-name">
                                      {" "}
                                      {item.platform}{" "}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="dash-coin-name">
                                      {" "}
                                      {item.browser}{" "}
                                    </span>
                                  </td>
                                  <td>
                                    <div className="dash_log_last">
                                      <span className="order_buy ">
                                        Successful
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </>
                          ) : (
                            <>
                              <tr className="text-center no-data-common">
                                <td colSpan="6">
                                  <span>No Datas Found</span>
                                </td>
                              </tr>
                            </>
                          )}
                        </>
                      )}

                      {/* <tr>
                                                <td>
                                                    <div className='order-place-frst'>
                                                        <span className='order-place-inner'>SPEC / USDT</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span className='dash-btm-price'>18.93</span>
                                                </td>
                                                <td>
                                                    <span className='dash-btm-price'>7.5966</span>
                                                </td>
                                                <td>
                                                    <span className='dash-btm-price'>7.6876</span>
                                                </td>
                                                <td>
                                                    <span className='dash-btm-date'>143.803638</span>
                                                </td>
                                                <td>
                                                    <span className='dash-btm-date'>145.372516</span>
                                                </td>
                                                <td>
                                                    <span className='dash-btm-date'>1.57</span>
                                                </td>
                                                <td>
                                                    <span className='dash-btm-date'>1.09</span>
                                                </td>
                                                <td>
                                                    <span className='dash-btm-date'>BYBIT</span>
                                                </td>
                                                <td>
                                                    <span className='dash-btm-date'>BYBIT</span>
                                                </td>
                                                <td>
                                                    <span className='dash-btm-date'>Intra</span>
                                                </td>
                                                <td>
                                                    <span className='dash-btm-date'>06/05/2024</span>
                                                </td>
                                            </tr>
                                           */}
                      {adminDetailsref.current.length > 0 ? (
                      <tr className="text-center text-white pagi_Btm_borno">
                        <td colSpan="12">
                          <div className="paginationcss">
                            <ReactPaginate
                              previousLabel={"<"}
                              nextLabel={">"}
                              breakLabel={"**"}
                              pageCount={loginCountsref.current / 10}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={0}
                              onPageChange={handlePageClick_trade}
                              containerClassName={
                                "pagination pagination-md justify-content-center"
                              }
                              pageClassName={"page-item"}
                              pageLinkClassName={"page-link"}
                              previousClassName={"page-item"}
                              previousLinkClassName={"page-link"}
                              nextClassName={"page-item"}
                              nextLinkClassName={"page-link"}
                              breakClassName={"page-item"}
                              breakLinkClassName={"page-link"}
                              activeClassName={"active"}
                            />
                          </div>
                        </td>
                      </tr>
                       ) : ("")} 
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginHistory;
