import React, { useState, useEffect, useRef } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { Dropdown } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import Image1 from "../images/Bitcoin-Logo.png";
import moment from "moment";
import ReactPaginate from "react-paginate";
import Pagination from "react-js-pagination";

import apiService from "../core/sevice/detail";
import { postMethod, getMethod } from "../core/sevice/common.api";
import { toast } from "react-toastify";
import { setAuthorization } from "../core/sevice/axios";

function Deposit() {
  const currencyOptions = [
    {
      key: 1,
      text: "USDT",
      value: "USDT",
      image: {
        avatar: true,
        src: "https://res.cloudinary.com/dvlfcoxxp/image/upload/v1715588917/USDT-logo_owxwcg.png",
      },
    },
  ];
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [adminBalance, setadminBalance] = useState(0);
  const [currency_address, setcurrency_address] = useState(false);
  const [qrcode, setqrcode] = useState();
  const currencylistref = useRef([]);
  const selectedCurrencyref = useRef("");
  const [currencylist, setCurrencyList] = useState([]);
  const [transacitonList, setTransactionList] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [perpage, setperpage] = useState(10);
  const [total, settotal] = useState(0);
  const [pageLoader, setPageLoader] = useState(false);
  const [refreshstatus, setrefreshstatus] = useState(false);

  const choose = () => {
    setSelectedCurrency(currencylist[0].value);
    setqrcode(currencylist[0].code);
    setcurrency_address(currencylist[0].currency_address);
  };

  const recordPerPage = 10;
  const pageRange = 10;

  useEffect(() => {
    get_admin_balance();
    get_wallet_list();
    get_admin_transactions();
  }, [0]);

  const get_admin_balance = async () => {
    setbuttonLoader(true);
    try {
      // var data = {
      //   apiUrl: apiService.get_admin_balance,
      // };
      setbuttonLoader(false);
      // var resp = await getMethod(data);
      setadminBalance(0);
    } catch (error) {}
  };

  const get_wallet_list = async () => {
    setbuttonLoader(true);
    try {
      var data = {
        apiUrl: apiService.get_wallet_list,
      };
      setbuttonLoader(false);
      var resp = await getMethod(data);

      const options = resp.data.map((item, index) => ({
        key: index, // Use a unique key, such as the index
        text: item.currencysymbol,
        value: item.currencysymbol,
        image: {
          avatar: true,
          src: item.currencyImage,
        },
        code:
          "https://quickchart.io/chart?cht=qr&chs=280x280&chl=" +
          item.wallet_address +
          "&choe=UTF-8&chld=L",
        currency_address: item.currencyAddress,
      }));
      setCurrencyList(options);
    } catch (error) {}
  };
  const get_admin_transactions = async (pages) => {
    try {
      setPageLoader(true);

      var obj = {
        perpage: perpage,
        page: pages,
      };
      var data = {
        apiUrl: apiService.get_admin_transactions,
        payload: obj,
      };
      var resp = await postMethod(data);

      setPageLoader(false);
      if (resp) {
        setTransactionList(resp.result);
        settotal(resp.totalCount);
      }
    } catch (error) {}
  };

  const addressCopy = () => {
    try {
      navigator.clipboard.writeText(currency_address);
      toast.success("Address copied successfully");
    } catch (error) {}
  };

  const handlePageChange = (pageNumber) => {
    setcurrentPage(pageNumber);
    get_admin_transactions(pageNumber);
  };

  const get_recent_deposit = async () => {
    setrefreshstatus(true);
    var data = {
      apiUrl: apiService.get_recent_deposit,
    };
    var resp = await getMethod(data);
    if (resp) {
      setrefreshstatus(false);
    }
  };

  return (
    <div>
      <Header />
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2 col-sm-3">
            <Sidebar />
          </div>

          <div className="col-lg-10 col-sm-9">
            {pageLoader == true ? (
              <div className="loader-css">
                <img
                  src={require("../images/Dual Ball@1x-1.0s-200px-200px.gif")}
                  alt="loader"
                  className="loader-gif"
                />
              </div>
            ) : (
              <div className="container-fluid dashboard-container">
                <span className="dashboard-portfolio">Deposit</span>

                <div className="row mt-4 deposit-row">
                  <div className="col-lg-6">
                    <div className="deposit-card">
                      {buttonLoader == true ? (
                        <>
                          <div className="loader-css">
                            <img
                              src={require("../images/Dual Ball@1x-1.0s-200px-200px.gif")}
                              alt="loader"
                              className="loader-gif"
                            />
                          </div>
                        </>
                      ) : (
                        <div className="deposit-inner">
                          <div className="deposit-card-top">
                            <span className="deposit-top-label">Currency</span>

                            <Dropdown
                              placeholder="Select Coin"
                              fluid
                              selection
                              options={currencylist}
                              onChange={choose}
                            />
                          </div>
                          {qrcode == null &&
                          qrcode == undefined &&
                          qrcode == null ? (
                            ""
                          ) : (
                            <div className="deposit-card-below">
                              <span className="dash-coin-name"> QR Code :</span>
                              <div className="or-deposit-box">
                                <div className="or-deposit-boxinner">
                                  <img
                                    // src={require("../images/QR-code.png")}
                                    src={qrcode}
                                    alt="or code"
                                    className="QR-Size"
                                  />
                                  <div class="or-line-container mt-2">
                                    <div class="or-line"></div>
                                    <div class="or-deposit">
                                      or copy the address
                                    </div>
                                    <div class="or-line"></div>
                                  </div>
                                </div>
                                <div>
                                  <div className="flex_input_posion">
                                    <input
                                      type="text"
                                      className="deposit-address"
                                      placeholder="Address"
                                      value={currency_address}
                                      disabled
                                    />
                                    <i
                                      class="fa-regular fa-copy deposi-copy"
                                      onClick={addressCopy}
                                    ></i>
                                    <div className="available">
                                      <p className="availablep">Available : </p>
                                      <p>{adminBalance}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="deposit-btm-history">
                  <div className="refresh">
                    <span className="dashboard-portfolio">History</span>
                    <span
                      className="dashboard-portfolio"
                      onClick={get_recent_deposit}
                    >
                      {refreshstatus == true ? (
                        <i class="fa-solid fa-arrows-rotate fa-spin refreshi"></i>
                      ) : (
                        <i class="fa-solid fa-arrows-rotate refreshi"></i>
                      )}{" "}
                      Refresh
                    </span>
                  </div>
                  <div class="table-responsive">
                    <table className="deposit-btm-table mt-4">
                      <thead className="dash-bot-head">
                        <th className="th-1">Currency</th>
                        <th>Date</th>
                        <th>Amount</th>
                        <th>Hash</th>
                      </thead>
                      <tbody>
                        {transacitonList && transacitonList.length > 0 ? (
                          transacitonList.map((item) => {
                            return (
                              <tr className="deposit-frst-row">
                                <td>
                                  <div className="dash-bottom-frst">
                                    <img
                                      src="https://res.cloudinary.com/dvlfcoxxp/image/upload/v1715588917/USDT-logo_owxwcg.png"
                                      className="dash-bottom-img"
                                      alt="btc-logo"
                                    />
                                    <div className="dash-bottom-inner">
                                      <span className="dash-coin-symbol">
                                        {item.currency}
                                      </span>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <span className="dash-btm-price">
                                    {" "}
                                    {item.createddate}
                                  </span>
                                </td>
                                <td>
                                  <span className="dash-btm-price">
                                    $ {item.amount}
                                  </span>
                                </td>
                                <td>
                                  <span className="dash-btm-price">
                                    {item.transaction_id}
                                  </span>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr className="text-center no-data-common">
                            <td colSpan="7" className="p-5">
                              <span>No Datas Found</span>
                            </td>
                          </tr>
                        )}
                        {transacitonList.length > 0 ? (
                             <tr className="text-center text-white">
                             <td colSpan="4">
                               <div className="paginate-deposit">
                                 <Pagination
                                   itemClass="page-item"
                                   linkClass="page-link"
                                   activePage={currentPage}
                                   itemsCountPerPage={recordPerPage}
                                   totalItemsCount={total}
                                   pageRangeDisplayed={pageRange}
                                   onChange={handlePageChange}
                                 />
                               </div>
                             </td>
                           </tr>
                        ) : (
                          ""
                        )}
                     

                        {/* <tr>
                      <td>
                        <div className="dash-bottom-frst">
                          <img
                            src={require("../images/Ethereum-logo.png")}
                            className="dash-bottom-img"
                            alt="btc-logo"
                          />
                          <div className="dash-bottom-inner">
                            <span className="dash-coin-name">Ethereum</span>
                            <span className="dash-coin-symbol">ETH</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span className="dash-btm-price">
                          {" "}
                          03/05/2024. 9:48:39 am
                        </span>
                      </td>
                      <td>
                        <span className="dash-btm-price">$ 1000</span>
                      </td>
                      <td>
                        <span className="dash-btm-price">xU345</span>
                      </td>
                      <td>
                        <span className="order-open">Completed</span>
                      </td>
                    </tr> */}
                      </tbody>
                      {/* {marketList.length > 0 ? ( */}

                      {/* ):("")} */}
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Deposit;
