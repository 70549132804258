import React, { useEffect } from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import ReactPaginate from "react-paginate";
import useState from "react-usestateref";
import apiService from "../core/sevice/detail";
import { getMethod } from "../core/sevice/common.api";
import moment from "moment";

import {socket} from "../core/context/socket";

function Market() {

  const [isLoading, setIsLoading] = useState(false);
  const [marketList, setMarketList] = useState([]);
  const [TotalPages, setTotalPages] = useState(0);


  useEffect(() => {
    socket.connect();
    getCoinMarket();

    socket.on("GetCurrentMarkets_user", async (response) => {
      console.log("GetCurrentMarkets_user",response);
      if(response != null)
      {
       if(response.data.length > 0)
       {
        setMarketList(response.data);
       }
      }
   });
  //   const interval = setInterval(() => {
  //     getCoinMarket();
  // }, 5000);
  // return () => clearInterval(interval);
  }, [])

  const getCoinMarket_socket = async (currentPage) => {
    let count = currentPage == undefined ? 1 : currentPage;
      var payload = {
        page: count,
        limit: 10
      }
      console.log("payload",payload)
      socket.emit("GetCurrentMarkets",payload);
  }

  const getCoinMarket = async (currentPage) => {
    let count = currentPage == undefined ? 1 : currentPage;
    try {
      var data = {
        apiUrl: apiService.getcoinmarket + `?page=${count}`,
      };
       setIsLoading(true);
      var resp = await getMethod(data);
      
      if (resp.status == true) {
        setIsLoading(false);
        setMarketList(resp.data);
        const total = resp.total;
        setTotalPages(Math.floor(total));
        //console.log(resp.data, "Yeah Market Goted");
      }

      // setTimeout(async () => {
      //   getCoinMarket_socket(currentPage);
      // },1000);
    } catch (error) {
      setIsLoading(false);
    }
  }

  const handlePageClick = async (data) => {
    console.log(data.selected);
    let currentPage = data.selected + 1;
    getCoinMarket(currentPage);
  };


  return (
    <div>
      <Header />

      <div className='container-fluid'>
        <div className='row'>
          <div className='col-lg-2 col-sm-3'>
            <Sidebar />
          </div>
          <div className='col-lg-10 col-sm-9'>
            <div className='container dashboard-container'>
              <span className='dashboard-portfolio'> Live Market Price</span>
              <div class="table-responsive">
                <table className='market-table mt-4'>
                  <thead className='market-head'>
                    <th>Currency</th>
                    <th>Price</th>
                    <th>1h%</th>
                    <th>Volume 24H</th>
                    <th>Market Cap</th>
                    <th>Date & Time</th>
                    <th>Market</th>
                  </thead>
                  <tbody>
                    {isLoading == true ? (
                      <>
                      <tr className='text-center'>
                        <td colSpan={7}>
                        <div className="loader-css">
                          <img
                            src={require("../images/Dual Ball@1x-1.0s-200px-200px.gif")}
                            alt="loader"
                            className="loader-gif"
                          />
                        </div>
                        </td>
                      </tr>
                       
                      </>
                    ):(
                    <>
                      {marketList.length > 0 ? (
                        <>
                          {marketList.map((item) => (
                            <tr>
                              <td>
                                <div className='dash-bottom-frst'>
                                  {/* <img src={require('../images/Bitcoin-Logo.png')} className='dash-bottom-img' alt='btc-logo' /> */}
                                  <img src={"https://s2.coinmarketcap.com/static/img/coins/64x64/"+item.id+".png"} className='dash-bottom-img' alt='btc-logo' />
                                  <div className='dash-bottom-inner'>
                                    <span className='dash-coin-name'>{item.name}</span>
                                    <span className='dash-coin-symbol'>{item.symbol}</span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <span className='dash-btm-price'> {parseFloat(item.quote.USD.price).toFixed(4)}</span>
                              </td>
                              <td>
                                {item.quote.USD.percent_change_1h > 0 ? (
                                  <>
                                    {/* <i class="fa-solid fa-caret-down fa-flip-vertical market-up"></i> */}
                                    <span className='dash-btm-pricesuccess'>
                                      {parseFloat(item.quote.USD.percent_change_1h).toFixed(4)}
                                    </span>
                                  </>
                                ) : (
                                  <span className='dash-btm-pricedanger'>
                                    {/* <i class="fa-solid fa-caret-down market-down"></i> */}
                                    {parseFloat(item.quote.USD.percent_change_1h).toFixed(4)}
                                  </span>
                                )}
                              </td>
                              <td>
                                <span className='dash-btm-price'>{parseFloat(item.quote.USD.volume_24h).toFixed(2)}</span>
                              </td>
                              <td>
                                <span className='dash-btm-price'>{parseFloat(item.quote.USD.market_cap).toFixed(2)}</span>
                              </td>
                              <td>
                                <span className='dash-btm-date'>{moment(item.quote.USD.last_updated).format("lll")}</span>
                              </td>
                              <td>
                                {/* <img src={require('../images/Vector 2.png')} alt='btc-coin' className='dash-btc-line' /> */}
                                {item.quote.USD.percent_change_1h > 0 ? (
                                <img src={"https://s3.coinmarketcap.com/generated/sparklines/web/7d/2781/"+item.id+".svg"} alt='btc-coin' className='dash-btc-line sc-14cb040a-0 dmOeak isUp' />
                                ) : (
                                  <img src={"https://s3.coinmarketcap.com/generated/sparklines/web/7d/2781/"+item.id+".svg"} alt='btc-coin' className='dash-btc-line sc-14cb040a-0 dmOeak' />
                                )}
                              </td>
                            </tr>
                          ))}
                        </>
                      ) : (
                        <>
                          <tr className="text-center no-data-common">
                            <td colSpan="7">
                              <span>No Datas Found</span>
                            </td>
                          </tr>
                        </>
                      )}
                    </>
                        )}


            {marketList.length > 0 ? (
                <tr className='text-center text-white'>
                <td colSpan="7">
                  <div className="paginationcss">
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"**"}
                      pageCount={TotalPages}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={1}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination pagination-md justify-content-center"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                </td>
              </tr>
            ):("")}
                  

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Market;